import { Image } from "react-bootstrap";
import logo from "../images/classroomCoach.webp";
import { useEffect, useState } from "react";
import { Chips } from "../../components/Chip";
import HSSelect from "../../components/Select";
import { getOrgDetailsFromCorC } from "../../redux/actions/orgnization";
import { useAppDispatch } from "../../hooks/app";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import ContentLoader from "react-content-loader";
import { Spinner } from "@material-tailwind/react";

interface Role {
  role_name: string;
  role_id: number;
  id: number;
  isSubDropDown: boolean;
  isDisabled?: boolean;
  sort_order: number;
}

const ClassroomCoachComponent = (props: {
  roles: Role[];
  org?: any;
  onDataChange: Function;
  data?: any;
  isChipSelected?: (e: any) => void;
  orgRole?: any;
  moduleType: any;
}) => {
  const dispatch = useAppDispatch();

  const org = useSelector((state: RootState) => state.organization);

  const userDetails = useSelector((state: RootState) => state.user.userDetails);

  const [mainRole, setMainRole] = useState<Role | null>(null);

  const [main_User_Role, set_Main_User_Role] = useState<any | undefined>();
  const [sub_User_Role, set_Sub_User_Role] = useState<any | undefined>();
  const [sites, setSites] = useState<any | undefined>();

  const [serviceCenters, setServiceCenters] = useState<any | undefined>();
  const [selectedServiceCenter, setSelectedServiceCenter] = useState<
    any | undefined
  >();
  const [subReceipients, setSubReceipients] = useState<any | undefined>();
  const [selectedSubReceipient, setSelectedSubReceipient] = useState<
    any | undefined
  >();
  const [subRole, setSubRole] = useState<Role | null>(null);

  const [selectedSites, setSelectedSites] = useState<any | undefined>([]);
  const [selectedLevel, setSelectedLevel] = useState<any | undefined>();

  console.log(
    "org?.corCData?.entityData?.isServiceCentersAdded: ",
    org?.corCData?.entityData?.isServiceCentersAdded
  );
  const [selectedSiteTeacher, setSelectedSiteTeacher] = useState<
    any | undefined
  >();
  const [classrooms, setClassrooms] = useState<any | undefined>();
  const [selectedClassrooms, setSelectedClassrooms] = useState<
    any | undefined
  >();
  const [selectedTeacherProfile, setSelectedTeacherProfile] = useState(null);
  const [mainRoleList, setMainRoleList] = useState<any>([]);
  const [subRoleList, setSubRoleList] = useState<any>([]);
  const [hierarchy, setHierarchy] = useState<any | undefined>();
  const [selectedHierarchy, setSelectedHierarchy] = useState<any | undefined>();
  const [isDisable, setIsDisable] = useState<boolean | undefined>(false);
  const [hierarchyDisable, setHierarchyDisable] = useState<boolean | undefined>(
    false
  );
  const [disableAll, setDisableAll] = useState<boolean | false>();

  const teacherProfiles = [
    { label: "Lead Teacher", value: "lead_teacher" },
    { label: "Teacher", value: "teacher" },
    { label: "Teacher Assistant", value: "teacher_assistant" },
    { label: "Aide", value: "aide" },
    { label: "Director", value: "director" },
    { label: "Substitute", value: "substitute" },
    { label: "Other", value: "other" },
  ];

  let levels = [
    { label: "Level 1", value: "level1" },
    { label: "Level 2", value: "level2" },
    { label: "Level 3", value: "level3" },
  ];

  // useEffect(() => {
  //  if(org?.corCData?.entity_data?.isServiceCentersAdded === false){
  //     levels =
  //  }
  // },[org?.corCData?.entity_data])

  useEffect(() => {
    const mainRoleData: Role[] = [
      ...props.roles.filter(
        (role) =>
          !role.isSubDropDown &&
          role?.role_id !== 100000 &&
          role?.role_id !== 100005
      ),
    ];
    const subRoleList: Role[] = props?.roles.filter(
      (role) => role.isSubDropDown
    );
    const orgRoleToCompare = props.orgRole || 0;
    const selectedRoleToCompare =
      props.data && props.data.selectedRole
        ? props.data.selectedRole?.role_id
        : 0;
    const isCreatingUser = !props.data || !props.data.selectedRole;
    const updatedMainRoleList: Role[] = mainRoleData.map((role) => {
      let isDisabled = false;
      if (!isCreatingUser && orgRoleToCompare !== 100000) {
        if (orgRoleToCompare === 100004 && selectedRoleToCompare != 100004) {
          setDisableAll(true);
        } else if (orgRoleToCompare === 100001) {
          setDisableAll(true);
        } else if (
          orgRoleToCompare === 100006 &&
          selectedRoleToCompare === 100001
        ) {
          setDisableAll(true);
        } else if (
          orgRoleToCompare === 100006 &&
          selectedRoleToCompare === 100001
        ) {
          setDisableAll(true);
        } else if (
          orgRoleToCompare === 100005 &&
          selectedRoleToCompare === 100001
        ) {
          setDisableAll(true);
        } else if (
          orgRoleToCompare === 100005 &&
          selectedRoleToCompare === 100000
        ) {
          setDisableAll(true);
        }
      }
      if (orgRoleToCompare === 0) {
        setDisableAll(true);
      }
      if (isCreatingUser) {
        switch (orgRoleToCompare) {
          case 100000:
            isDisabled = false;
            break;
          case 100002:
            isDisabled = false;
            break;
          case 100001:
            isDisabled = true;
            break;
          case 100004:
            isDisabled = role?.role_id === 100002;
            break;
          case 100006:
            isDisabled = role?.role_id === 100002 || role?.role_id === 100001;
            break;
          default:
            isDisabled = true;
            break;
        }
      } else {
        switch (orgRoleToCompare) {
          case 100000:
            isDisabled = false;
            break;
          case 100002:
            isDisabled = false;
            break;
          case 100001:
            isDisabled = true;
            break;
          case 100004:
            isDisabled =
              selectedRoleToCompare === 100002 ||
              (selectedRoleToCompare === 100004 && role?.role_id !== 100004);
            break;
          case 100006:
            isDisabled = role?.role_id === 100002 || role?.role_id === 100001;
            break;

          default:
            isDisabled = true;
            break;
        }
      }
      return {
        ...role,
        isDisabled,
      };
    });

    const updatedSubRoleList: Role[] = subRoleList.map((role) => ({
      ...role,
      isDisabled:
        (orgRoleToCompare === 100004 &&
          (selectedRoleToCompare === 100001 || role?.role_id === 100001)) ||
        (orgRoleToCompare === 100004 &&
          (selectedRoleToCompare === 100004 || role?.role_id === 100001)) ||
        (isCreatingUser &&
          orgRoleToCompare === 100001 &&
          role?.role_id === 100002) ||
        (isCreatingUser &&
          orgRoleToCompare === 100006 &&
          role?.role_id === 100001),
    }));

    setMainRoleList(updatedMainRoleList);
    setSubRoleList(updatedSubRoleList);
  }, [props]);

  useEffect(() => {
    if (props?.org) {
      const body = {
        hs_id: props?.org.id,
        hs_uuid: props?.org.uuid,
        hs_userId: userDetails?.id,
        applications: "COR-C",
        action: "HS_GET_DETAILS",
      };
      dispatch(getOrgDetailsFromCorC(body));
    }
  }, [props?.org]);

  useEffect(() => {
    if (org?.corCData?.entity_data) {
      setServiceCenters(org?.corCData?.entity_data?.serviceCenters);
    }
  }, [org?.corCData, props.data]);

  useEffect(() => {
    if (!org?.corCData?.entity_data) {
      return;
    }
    const { entity_data } = org?.corCData;

    const { sites, cc_users } = entity_data;
    if (Array.isArray(cc_users) && cc_users.length > 0) {
      const { Role, Level } = cc_users[0];
      if (main_User_Role == 100004) {
        let siteOptions: any = [];
        let classOptions: any = [];
        if (sub_User_Role == 100006) {
          let orgOptions: any = [];
          if (Role == 100002 && Level?.value == 2) {
            sites?.forEach((site: any) => {
              if (selectedHierarchy && selectedHierarchy.length > 0) {
                if (site?.ServiceCenter.value === selectedHierarchy[0]?.value) {
                  siteOptions.push({
                    Center_Name: site?.Center_Name,
                    _id: site._id,
                  });
                }
              }
            });
          } else if (Role == 100002 && Level?.value == 3) {
            sites.forEach((site: any) => {
              if (selectedHierarchy && selectedHierarchy?.length > 0) {
                if (site?.Heirarchy_Id === selectedHierarchy[0]?.value) {
                  siteOptions.push({
                    Center_Name: site?.Center_Name,
                    _id: site?._id,
                  });
                }
              }
            });
          } else {
            if (entity_data?.isServiceCentersAdded) {
              const { serviceCenters } = entity_data;

              serviceCenters.forEach((data: any) => {
                if (data?.isSubReceipientPresent) {
                  data?.subReceipients.forEach((rec: any) => {
                    orgOptions.push({
                      label: rec?.SubReceipient_Name,
                      value: rec?._id,
                      level: "subRecipient",
                    });

                    if (rec._id === selectedHierarchy?.value) {
                      rec.sites.filter((sites: any) => {
                        siteOptions.push({
                          Center_Name: sites?.Center_Name,
                          _id: sites?._id,
                        });
                      });
                    }
                  });
                } else {
                  orgOptions.push({
                    label: data?.Service_Name,
                    value: data?._id,
                    level: "serviceCenter",
                  });
                  if (data._id === selectedHierarchy?.value) {
                    data.sites.filter((sites: any) => {
                      siteOptions.push({
                        Center_Name: sites?.Center_Name,
                        _id: sites?._id,
                      });
                    });
                  }
                }
              });
            } else {
              const { Entity_Name, _id, sites } = entity_data;
              orgOptions.push({
                label: Entity_Name,
                value: _id,
                level: "entity",
              });
              if (_id === selectedHierarchy?.value) {
                sites.length > 0 &&
                  sites.map((site: any) => {
                    return siteOptions.push({
                      Center_Name: site?.Center_Name,
                      _id: site?._id,
                    });
                  });
              }
            }
          }
          setHierarchy(orgOptions);
        }
        if (sub_User_Role == 100001) {
          let orgOptions: any = [];
          if (Role == 100002) {
            sites.forEach((site: any) => {
              if (selectedHierarchy && selectedHierarchy.length > 0) {
                if (
                  site?.ServiceCenter?.value === selectedHierarchy[0]?.value
                ) {
                  siteOptions.push({
                    Center_Name: site?.Center_Name,
                    _id: site?._id,
                  });
                }
              }
            });
          } else {
            if (entity_data?.isServiceCentersAdded) {
              const { serviceCenters } = entity_data;

              serviceCenters.forEach((data: any) => {
                orgOptions.push({
                  label: data?.Service_Name,
                  value: data?._id,
                  level: "serviceCenter",
                });
                if (data._id === selectedHierarchy?.value) {
                  data.sites.filter((sites: any) => {
                    siteOptions.push({
                      Center_Name: sites?.Center_Name,
                      _id: sites?._id,
                    });
                  });
                }
              });
            } else {
              const { Entity_Name, _id, sites } = entity_data;
              orgOptions.push({
                label: Entity_Name,
                value: _id,
                level: "entity",
              });
              if (_id === selectedHierarchy?.value) {
                sites.length > 0 &&
                  sites.map((site: any) => {
                    return siteOptions.push({
                      Center_Name: site?.Center_Name,
                      _id: site?._id,
                    });
                  });
              }
            }
          }
          setHierarchy(orgOptions);
        } else if (sub_User_Role == 100004) {
          const { sites } = entity_data;
          sites.forEach((siteData: any) => {
            siteOptions.push({
              Center_Name: siteData?.Center_Name,
              _id: siteData?._id,
            });
            siteData.classes.forEach((classData: any) => {
              if (siteData._id === selectedSiteTeacher?._id) {
                classOptions.push({
                  Class_Name: classData?.Class_Name,
                  _id: classData?._id,
                });
              }
            });
          });
        }
        if (entity_data?.isServiceCentersAdded) {
          setSites(siteOptions);
        } else {
          let filerCenters: any = [];
          entity_data?.sites?.map((siteData: any) => {
            filerCenters.push({
              Center_Name: siteData?.Center_Name,
              _id: siteData?._id,
            });
          });

          setSites(filerCenters);
        }
        setClassrooms(classOptions);
      }

      if (selectedServiceCenter) {
        setSubReceipients(selectedServiceCenter.subReceipients);
      }
    }
  }, [
    org?.corCData,
    main_User_Role,
    sub_User_Role,
    selectedHierarchy,
    selectedSiteTeacher,
    selectedServiceCenter,
    selectedSubReceipient,
    org?.corCData?.entity_data?.isServiceCentersAdded,
  ]);

  useEffect(() => {
    if (!org?.corCData?.entity_data) {
      return;
    }
    const { entity_data } = org?.corCData;
    if (main_User_Role == 100002) {
      let sericeOptions: any = [];
      let recepientOptions: any = [];
      const { cc_users } = entity_data;
      if (Array.isArray(cc_users) && cc_users.length > 0) {
        const { Role, Level, ServiceCenter, SubReceipient } = cc_users[0];
        if (Role == 100002 && Level) {
          if (Level?.value == 1) {
            setSelectedLevel({ label: "Level 1", value: "level1" });
            setIsDisable(true);
            setHierarchyDisable(false);
          } else if (Level?.value == 2) {
            setSelectedLevel({ label: "Level 2", value: "level2" });
            sericeOptions.push({
              Service_Name: ServiceCenter?.label,
              _id: ServiceCenter?.value,
            });
            setIsDisable(true);
            setHierarchyDisable(true);
          } else if (Level?.value == 3) {
            setSelectedLevel({ label: "Level 3", value: "level3" });
            sericeOptions.push({
              Service_Name: ServiceCenter?.label,
              _id: ServiceCenter?.value,
            });
            recepientOptions.push({
              SubReceipient_Name: SubReceipient?.label,
              _id: SubReceipient?.value,
            });
            setIsDisable(true);
            setHierarchyDisable(true);
          }
        }
        setSelectedServiceCenter(sericeOptions[0]);
        setSelectedSubReceipient(recepientOptions[0]);
      }
    } else if (main_User_Role == 100004) {
      let hierarchyOptions: any = [];
      let siteOptions: any = [];
      const { cc_users, sites } = entity_data;
      if (Array.isArray(cc_users) && cc_users.length > 0) {
        const { Role, Level, ServiceCenter, SubReceipient } = cc_users[0];
        if (sub_User_Role == 100001 || sub_User_Role == 100006) {
          if (Role == 100002 && (Level?.value == 2 || Level?.value == 3)) {
            if (Level?.value == 2) {
              setSelectedLevel({ label: "Level 2", value: "level2" });
              hierarchyOptions.push({
                label: ServiceCenter?.label,
                value: ServiceCenter?.value,
              });
              setHierarchyDisable(true);
            } else if (Level?.value == 3) {
              setSelectedLevel({ label: "Level 3", value: "level3" });
              hierarchyOptions.push({
                label: SubReceipient?.label,
                value: SubReceipient?.value,
              });
              setHierarchyDisable(true);
            }
            setSelectedHierarchy(hierarchyOptions);
          }
          sites.forEach((site: any) => {
            if (hierarchyOptions.length > 0) {
              if (site.Heirarchy_Id === hierarchyOptions[0]?.value) {
                siteOptions.push({
                  Center_Name: site?.Center_Name,
                  _id: site?._id,
                });
              }
            }
          });
        }
        if (entity_data?.isServiceCentersAdded) {
          setSites(siteOptions);
        } else {
          let filerCenters: any = [];
          entity_data?.sites?.map((siteData: any) => {
            filerCenters.push({
              Center_Name: siteData?.Center_Name,
              _id: siteData?._id,
            });
          });

          setSites(filerCenters);
        }
      }
    }
  }, [org?.corCData, main_User_Role, sub_User_Role]);

  const handleSelectRole = (role: Role) => {
    if (role?.role_id === 100002) {
      set_Main_User_Role(role?.role_id);
    } else {
      set_Main_User_Role(100004);
      set_Sub_User_Role(role?.role_id);
    }
    if (role?.role_id === 100002) {
      setMainRole(role);
    } else {
      setMainRole({
        role_name: "Staff",
        role_id: 100004,
        id: 16,
        isSubDropDown: false,
        sort_order: 4,
      });
      if (role?.isSubDropDown) {
        setSubRole(role);
      }
    }
    props.isChipSelected && props.isChipSelected(true);
  };

  useEffect(() => {
    if (props?.data && org?.corCData) {
      if (props?.data?.application_role) {
        handleSelectRole(props.data?.application_role);
      }

      if (props?.data?.application_role?.role_id === 100002) {
        switch (props?.data?.other2) {
          case "level1":
            setSelectedLevel({ label: "Level 1", value: "level1" });
            setSelectedServiceCenter({});
            setSelectedSubReceipient({});
            break;
          case "level2":
            setSelectedLevel({ label: "Level 2", value: "level2" });
            const matchingServiceCenterObject =
              org?.corCData?.entity_data?.serviceCenters?.filter(
                (item: any) => item._id === props.data.other3
              );
            setSelectedServiceCenter(matchingServiceCenterObject);
            setSelectedSubReceipient({});
            break;
          case "level3":
            setSelectedLevel({ label: "Level 3", value: "level3" });
            const matchingServiceCenter =
              org?.corCData?.entity_data?.serviceCenters?.filter(
                (item: any) => item._id === props.data.other3
              );
            const matchingSubReceipient =
              matchingServiceCenter[0].subReceipients?.filter(
                (item: any) => item._id === props.data.other4
              );
            setSelectedServiceCenter(matchingServiceCenter);
            setSelectedSubReceipient(matchingSubReceipient);
            break;

          default:
            break;
        }
      }
      if (props?.data?.siteDetails && props?.data?.siteDetails?.length > 0) {
        let obj: any = [];

        if (
          org?.corCData?.entity_data?.sites &&
          org.corCData.entity_data?.sites.length > 0
        ) {
          props.data.siteDetails.forEach((siteDetail: any) => {
            const matchingSite = org?.corCData?.entity_data?.sites.find(
              (orgSite: any) => orgSite?._id === siteDetail?.siteId
            );
            if (matchingSite) {
              obj.push(matchingSite);
            }
          });
        }

        if (props?.data?.application_role?.role_id === 100004) {
          setSelectedSiteTeacher(obj);
          const selectedRoles: any = teacherProfiles.filter(
            (role: any) => role.label === props.data.other1
          );
          setSelectedTeacherProfile(
            selectedRoles?.length > 0 ? selectedRoles[0] : []
          );
        } else {
          setSelectedSiteTeacher(obj);
          switch (props.data.other2) {
            case "entity":
              setSelectedHierarchy({
                label: org?.corCData?.entity_data?.Entity_Name,
                value: org?.corCData?.entity_data?._id,
                level: "entity",
              });
              break;
            case "serviceCenter":
              const matchingServiceCenterObject = obj.find(
                (item: any) => item.ServiceCenter.value === props.data.other3
              );
              setSelectedHierarchy({
                label: matchingServiceCenterObject?.ServiceCenter?.label,
                value: matchingServiceCenterObject?.ServiceCenter?.value,
                level: "serviceCenter",
              });

              break;
            case "subRecipient":
              const matchingSubReceipient = obj.find(
                (item: any) => item.SubReceipient.value === props.data.other3
              );
              setSelectedHierarchy({
                label: matchingSubReceipient?.SubReceipient?.label,
                value: matchingSubReceipient?.SubReceipient?.value,
                level: "subRecipient",
              });
              break;

            default:
              break;
          }
        }
      }
      if (props.data?.classDetails?.length > 0) {
        const obj = {
          Class_Name: props?.data?.classDetails[0]?.className,
          _id: props?.data?.classDetails[0]?.classId,
        };
        setSelectedClassrooms(obj);
      }
    }
  }, [props.data, org?.corCData]);

  const handleDataChange = (data: any) => {
    props.onDataChange(data);
  };

  const clearData = () => {
    setSubRole(null);
    setClassrooms(null);
    setSelectedSites(null);
    setSelectedSiteTeacher(null);
  };

  useEffect(() => {
    if (mainRole?.role_id === 100000) {
      clearData();
    }
    if (mainRole?.role_id === 100001) {
      clearData();
    }
    if (mainRole?.role_id === 100004) {
      clearData();
    }
  }, []);

  useEffect(() => {
    handleDataChange({
      mainRole,
      selectedSites,
      selectedSite: selectedSiteTeacher,
      selectedClassrooms,
      subRole,
      selectedTeacherProfile,
      selectedServiceCenter,
      selectedSubReceipient,
      selectedLevel,
      selectedHierarchy,
    });
  }, [
    mainRole,
    selectedSites,
    selectedSiteTeacher,
    selectedClassrooms,
    subRole,
    selectedTeacherProfile,
    selectedServiceCenter,
    selectedSubReceipient,
    selectedLevel,
    selectedHierarchy,
  ]);

  if (
    org?.corCData?.entity_data?.cc_users?.length < 1 &&
    props.moduleType === "Edit"
  ) {
    return (
      <div className="pl-[45%] py-[8%]">
        <Spinner className="h-8 w-8 text-white text-center" color="blue" />;
      </div>
    );
  } else {
    return (
      <div className="overflow-auto relative">
        <div className="grid grid-cols-6 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1">
          <Image
            className="px-3 py-2"
            src={logo}
            width={180}
            height={70}
            alt="COR Advantage"
          />
          <div className="grid md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 items-center col-span-5">
            <Chips
              header={"Select Role in the Organization"}
              roles={mainRoleList}
              selectedRole={mainRole}
              onSelectRole={handleSelectRole}
            />
          </div>
        </div>
        {disableAll && (
          <div className="text-yellow-700 font-semibold">
            ***You are not allowed to modify this user's details!!!
          </div>
        )}

        {mainRole?.role_id === 100002 && (
          <div className="grid grid-cols-3 md:grid-cols-1 pl-20 sm:pl-5 xs:pl-5 sm:grid-cols-1 xs:grid-cols-1 items-center pr-5 pt-2">
            <div className="w-full text-left">
              <div className="flex flex-col ml-24 md:ml-0 sm:ml-0 xs:ml-0">
                <HSSelect
                  label={"Select Level"}
                  placeholder="Select Level"
                  options={
                    org?.corCData?.entity_data?.isServiceCentersAdded === false
                      ? [{ label: "Level 1", value: "level1" }]
                      : levels
                  }
                  isMulti={false}
                  closeMenuOnSelect={true}
                  required={true}
                  value={selectedLevel}
                  onChange={(obj: any) => {
                    setSelectedLevel(obj);
                    setSelectedServiceCenter(undefined);
                    setSelectedSubReceipient(undefined);
                  }}
                  disabled={isDisable || disableAll}
                />
              </div>
            </div>
            {selectedLevel &&
              org?.corCData?.entity_data?.isServiceCentersAdded && (
                <>
                  {selectedLevel?.value === "level2" && (
                    <div className="w-auto pl-2 pb-1 gap-2 text-left mt-1 grid grid-cols-1 md:grid-cols-1 sm:pl-5 xs:pl-5 sm:grid-cols-1 xs:grid-cols-1">
                      <div className="w-full mr-28">
                        <HSSelect
                          label={"Select Service Center"}
                          placeholder="Select Service Center"
                          options={serviceCenters}
                          isMulti={false}
                          closeMenuOnSelect={true}
                          required={true}
                          getOptionLabel={(option: any) => option.Service_Name}
                          getOptionValue={(option: any) => option._id}
                          value={selectedServiceCenter}
                          onChange={(obj: any) => {
                            setSelectedServiceCenter(obj);
                            setSelectedSubReceipient(undefined);
                          }}
                          disabled={isDisable || disableAll}
                        />
                      </div>
                    </div>
                  )}

                  {selectedLevel?.value === "level3" && (
                    <div className="w-auto pl-2 pb-1 gap-2 col-span-2  text-left mt-1 grid grid-cols-2 md:grid-cols-1 sm:pl-5 xs:pl-5 sm:grid-cols-1 xs:grid-cols-1">
                      <div className="w-full mr-28">
                        <HSSelect
                          label={"Select Service Center"}
                          placeholder="Select Service Center"
                          options={serviceCenters}
                          isMulti={false}
                          closeMenuOnSelect={true}
                          required={true}
                          getOptionLabel={(option: any) => option.Service_Name}
                          getOptionValue={(option: any) => option._id}
                          value={selectedServiceCenter}
                          onChange={(obj: any) => {
                            setSelectedSubReceipient(null);
                            setSelectedServiceCenter(obj);
                          }}
                          disabled={isDisable || disableAll}
                        />
                      </div>
                      <div className="w-full mr-28">
                        <HSSelect
                          label={"Select Sub Recipient"}
                          placeholder="Select Sub Recipient"
                          options={subReceipients}
                          isMulti={false}
                          closeMenuOnSelect={true}
                          required={true}
                          getOptionLabel={(option: any) =>
                            option.SubReceipient_Name
                          }
                          getOptionValue={(option: any) => option._id}
                          value={selectedSubReceipient}
                          onChange={(obj: any) => {
                            setSelectedSubReceipient(obj);
                          }}
                          disabled={isDisable || disableAll}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
          </div>
        )}

        {mainRole?.role_id === 100004 && (
          <div className="grid grid-cols-3 md:grid-cols-1 pl-20 sm:pl-5 xs:pl-5 sm:grid-cols-1 xs:grid-cols-1 items-center pr-5 pt-2">
            <div className="w-full text-left">
              <div className="flex flex-col ml-24">
                <HSSelect
                  label={"Select Profile"}
                  placeholder="Select Profile"
                  options={subRoleList}
                  isMulti={false}
                  closeMenuOnSelect={true}
                  required={true}
                  getOptionLabel={(option: Role) => option.role_name}
                  getOptionValue={(option: Role) => option.id}
                  value={subRole}
                  onChange={(obj: any) => {
                    setSubRole(obj);
                    set_Sub_User_Role(obj?.role_id);
                    setSelectedHierarchy(null);
                    setSelectedSiteTeacher(null);
                    setSelectedClassrooms(null);
                    setSelectedTeacherProfile(null);
                  }}
                  disabled={disableAll}
                />
              </div>
            </div>
            {subRole && (
              <>
                {subRole?.role_id === 100001 && (
                  <div className="w-auto pl-2 pb-1 gap-2 col-span-2  text-left mt-1 grid grid-cols-3 md:grid-cols-1 sm:pl-5 xs:pl-5 sm:grid-cols-1 xs:grid-cols-1">
                    {org?.corCData?.entity_data?.isServiceCentersAdded && (
                      <div className="w-full mr-28">
                        <HSSelect
                          label={"Select Hierarchy"}
                          placeholder="Select Hierarchy"
                          options={hierarchy}
                          isMulti={false}
                          closeMenuOnSelect={false}
                          required={true}
                          getOptionLabel={(option: any) => option.label}
                          getOptionValue={(option: any) => option.value}
                          value={selectedHierarchy}
                          onChange={(obj: any) => {
                            setSelectedHierarchy(obj);
                            setSelectedSiteTeacher(null);
                          }}
                          disabled={hierarchyDisable || disableAll}
                        />
                      </div>
                    )}
                    {subRole?.role_id === 100001 && (
                      <div className="w-full mr-28">
                        <HSSelect
                          label={"Select Sites"}
                          placeholder="Select Sites"
                          options={sites}
                          isMulti={true}
                          closeMenuOnSelect={false}
                          required={true}
                          getOptionLabel={(option: any) => option.Center_Name}
                          getOptionValue={(option: any) => option._id}
                          value={selectedSiteTeacher}
                          onChange={(obj: any) => {
                            setSelectedSiteTeacher(obj);
                          }}
                          disabled={disableAll}
                        />
                      </div>
                    )}
                  </div>
                )}
                {subRole?.role_id === 100006 &&
                  org?.corCData?.entity_data?.isServiceCentersAdded && (
                    <div className="w-auto pl-2 pb-1 gap-2 col-span-2  text-left mt-1 grid grid-cols-3 md:grid-cols-1 sm:pl-5 xs:pl-5 sm:grid-cols-1 xs:grid-cols-1">
                      <div className="w-full mr-28">
                        <HSSelect
                          label={"Select Hierarchy"}
                          placeholder="Select Hierarchy"
                          options={hierarchy}
                          isMulti={false}
                          closeMenuOnSelect={false}
                          required={true}
                          getOptionLabel={(option: any) => option.label}
                          getOptionValue={(option: any) => option.value}
                          value={selectedHierarchy}
                          onChange={(obj: any) => {
                            setSelectedHierarchy(obj);
                            setSelectedSiteTeacher(null);
                          }}
                          disabled={hierarchyDisable || disableAll}
                        />
                      </div>
                      {subRole?.role_id === 100006 && (
                        <div className="w-full mr-28">
                          <HSSelect
                            label={"Select Sites"}
                            placeholder="Select Sites"
                            options={sites}
                            isMulti={true}
                            closeMenuOnSelect={false}
                            required={true}
                            getOptionLabel={(option: any) => option.Center_Name}
                            getOptionValue={(option: any) => option._id}
                            value={selectedSiteTeacher}
                            onChange={(obj: any) => {
                              setSelectedSiteTeacher(obj);
                            }}
                            disabled={disableAll}
                          />
                        </div>
                      )}
                    </div>
                  )}
              </>
            )}

            {subRole && (
              <>
                {subRole?.role_id === 100004 && (
                  <div className="w-auto pl-2 pb-1 gap-2 col-span-2  text-left mt-1 grid grid-cols-3 md:grid-cols-1 sm:pl-5 xs:pl-5 sm:grid-cols-1 xs:grid-cols-1">
                    <div className="w-full">
                      <HSSelect
                        label={"Select Site"}
                        placeholder="Select Site"
                        options={sites}
                        isMulti={false}
                        closeMenuOnSelect={false}
                        required={true}
                        getOptionLabel={(option: any) => option.Center_Name}
                        getOptionValue={(option: any) => option._id}
                        value={selectedSiteTeacher}
                        onChange={(obj: any) => {
                          setSelectedClassrooms(null);
                          setSelectedSiteTeacher(obj);
                        }}
                        disabled={disableAll}
                      />
                    </div>
                    <div className="w-full">
                      <HSSelect
                        label={"Select Classroom"}
                        placeholder="Select Classroom"
                        options={classrooms}
                        isMulti={false}
                        closeMenuOnSelect={false}
                        required={true}
                        getOptionLabel={(option: any) => option.Class_Name}
                        getOptionValue={(option: any) => option._id}
                        value={selectedClassrooms}
                        onChange={(obj: any) => {
                          setSelectedClassrooms(obj);
                        }}
                        disabled={disableAll}
                      />
                    </div>
                    <div className="w-full">
                      <HSSelect
                        label={"Select Teacher Profile"}
                        placeholder="Select Teacher Profile"
                        options={teacherProfiles}
                        isMulti={false}
                        closeMenuOnSelect={false}
                        required={true}
                        value={selectedTeacherProfile}
                        onChange={(obj: any) => {
                          setSelectedTeacherProfile(obj);
                        }}
                        disabled={disableAll}
                      />
                    </div>
                  </div>
                )}

                <div className="w-full pl-5 text-left mt-1"></div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
};

export default ClassroomCoachComponent;
