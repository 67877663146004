import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Container, Image } from "react-bootstrap";
import { IdTokenData } from "../components/DataDisplay";
import {
  BuildingOfficeIcon,
  ChevronDownIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

import {
  CheckBadgeIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid";
import TextInput from "../components/Input";
import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Button,
  Card,
  Checkbox,
  Input,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Option,
  Select,
  Typography,
} from "@material-tailwind/react";
import ContentLoader from "react-content-loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { useAppDispatch } from "../hooks/app";
import { getAllApplications } from "../redux/actions/application";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { validateEmailId } from "../utils";
import { isEmpty } from "lodash";
import HSDialog from "../components/Dialog";
import {
  createOrgnization,
  resetCreateOrgFlag,
} from "../redux/actions/orgnization";
// import countryList from "react-select-country-list";
import countryList from "../utils/countrylist.json";
import HSSelect from "../components/Select";
import {
  ORGNIZATION_LEVEl,
  ORGNIZATION_PROGRAM_TYPE,
  ORGNIZATION_REGION,
  SUBSCRIPTION_TYPE,
} from "../utils/constant";
import moment from "moment-timezone";

export const CreateOrgnization = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [address1, setAddress1] = useState("");
  const [address1Error, setAddress1Error] = useState("");
  const [address2, setAddress2] = useState("");
  const [contactPersonName, setContactPersonName] = useState("");
  const [contactPersonNameError, setContactPersonNameError] = useState("");
  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [country, setCountry] = useState<any>(undefined);
  const [countryError, setCountryError] = useState("");
  const [region, setRegion] = useState<any>(undefined);
  const [regionError, setRegionError] = useState<any>("");
  const [level, setLevel] = useState<any>(undefined);
  const [levelError, setLevelError] = useState("");
  const [programType, setProgramType] = useState<any>(undefined);
  const [programTypeError, setProgramTypeError] = useState("");
  const [state, setState] = useState("");
  const [stateError, setStateError] = useState("");
  const [contact1, setContact1] = useState("");
  const [contact2, setContact2] = useState("");
  const [pincode, setPincode] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [alertDialog, showAlertDialog] = useState<boolean | null>(null);
  const [ccService, setCcService] = useState<Array<any>>([]);
  const application = useSelector((state: RootState) => state.application);
  const org = useSelector((state: RootState) => state.organization);
  const [applications, setApplications] = useState<Array<any>>([]);
  const [open, setOpen] = useState<number>(1);
  const [selectdApp, setSelectedApps] = useState<any>({});
  const [value, setValue] = useState<any>({
    startDate: null,
    endDate: null,
  });
  const dispatch = useAppDispatch();

  const handleValueChange = (newValue: any) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(getAllApplications());
  }, []);

  useEffect(() => {
    if (org.orgnizationCreated) {
      resetData();
      dispatch(resetCreateOrgFlag());
    }
  }, [org.orgnizationCreated]);

  const toggleHeader = (value: number) => {
    if (open === value) {
      handleOpen(-1);
    } else {
      handleOpen(value);
    }
  };
  const handleOpen = (value: number) => setOpen(open === value ? 0 : value);

  const addApplication = () => {
    const temAppList = [...applications];
    // initialApplication.id = temAppList.length + 1
    // temAppList.push(initialApplication);
    setApplications(temAppList);
  };

  const initialApplication = {
    id: 0,
    headerTitle: "",
    logo: "",
    applicationType: "",
    role: "",
    class: "",
    organization: "",
    code: "",
  };

  const onSelectOption = (menu: any, type: "DELETE" | "ADD") => {
    const temAppList = [...applications];
    if (type === "ADD" && isSelected(menu.applicationCode) === -1) {
      const menuData: any = menuList.find(
        (e) => e.applicationCode === menu.applicationCode
      );
      initialApplication.id = menu.applicationCode;
      initialApplication.headerTitle = menuData?.name || "";
      initialApplication.logo = menuData?.logo || "";
      initialApplication.code = menuData.applicationCode;

      let tempSelected: any = { ...selectdApp };
      if (
        tempSelected &&
        !tempSelected.hasOwnProperty(menuData.applicationCode)
      ) {
        tempSelected[menuData.applicationCode] = {
          startDate: undefined,
          endDate: undefined,
          license: "",
          applicationId: menu.id,
          subscription_type: { label: "Yearly", value: "Yearly" },
        };
      } else {
        tempSelected = {
          [menuData.applicationCode]: {
            startDate: undefined,
            endDate: undefined,
            license: "",
            applicationId: menu.id,
            subscription_type: { label: "Yearly", value: "Yearly" },
          },
        };
      }
      setSelectedApps(tempSelected);
      temAppList.push(initialApplication);
      setApplications(temAppList);
      handleOpen(applications.length);
    } else if (type === "DELETE") {
      temAppList.splice(isSelected(menu), 1);
      let tempSelected: any = { ...selectdApp };
      delete tempSelected[menu];
      setSelectedApps(tempSelected);
      setApplications(temAppList);
    }
  };

  const isSelected = (menuId: any) => {
    return applications.findIndex((element) => element.code == menuId);
  };

  const menuList = [
    {
      id: 1,
      name: "COR Advantage",
      logo: "/images/coreA.webp",
      applicationCode: "COR-A",
    },
    {
      id: 2,
      name: "COR FOR Kindergarten",
      logo: "/images/cork.webp",
      applicationCode: "COR-K",
    },
    {
      id: 3,
      name: "Classroom Coach",
      logo: "/images/classroomCoach.webp",
      applicationCode: "COR-C",
    },
    {
      id: 4,
      name: "Ready School Assessment",
      logo: "/images/logoRSA.webp",
      applicationCode: "COR-R",
    },
    {
      id: 6,
      name: "Letter Links",
      logo: "/images/letterLink.webp",
      applicationCode: "COR-L",
    },
    {
      id: 6,
      name: "Program Quality Assessment",
      logo: "/images/logoPQA.webp",
      applicationCode: "COR-P",
    },
    {
      id: 7,
      name: "Curriculum",
      logo: "/images/letterLink.webp",
      applicationCode: "COR-CU",
    },
  ];

  const onValunChange = (key: string, chiledkey: string, value: any) => {
    let tempSelected: any = { ...selectdApp };
    tempSelected[key][chiledkey] = value;
    delete tempSelected[key][chiledkey + "Error"];
    setSelectedApps(tempSelected);
  };

  const resetData = () => {
    setName("");
    setNameError("");
    setAddress1("");
    setAddress1Error("");
    setAddress2("");
    setPhone("");
    setPhoneError("");
    setCountry(null);
    setCountryError("");
    setRegion(null);
    setRegionError("");
    setLevel("");
    setLevelError("");
    setProgramType("");
    setProgramTypeError("");
    setContactPersonName("");
    setContactPersonNameError("");
    setCity("");
    setCityError("");
    setState("");
    setStateError("");
    setContact1("");
    setContact2("");
    setPincode("");
    setPincodeError("");
    setEmail("");
    setEmailError("");
    showAlertDialog(null);
    setSelectedApps({});
    setApplications([]);
    setCcService([]);
  };

  const onSubmit = () => {
    if (!name) {
      setNameError("Please enter name");
      return;
    }

    if (!contactPersonName) {
      setContactPersonNameError("Please enter contact person name");
      return;
    }

    if (!email) {
      setEmailError("Please enter email");
      return;
    }

    if (!validateEmailId(email)) {
      setEmailError("Please enter valid email");
      return;
    }

    if (!address1) {
      setAddress1Error("Please enter address");
      return;
    }

    if (!city) {
      setCityError("Please enter city");
      return;
    }

    if (!state) {
      setStateError("Please enter state");
      return;
    }

    if (!country) {
      setCountryError("Please select country");
      return;
    }

    if (!pincode) {
      setPincodeError("Please enter pincode");
      return;
    }

    if (!phone) {
      setPhoneError("Please enter phone");
      return;
    }

    if (phone && phone.length !== 10) {
      setPhoneError("Phone number must be 10 digit");
      return;
    }

    if (!region) {
      setRegionError("Please select region");
      return;
    }

    if (!level) {
      setLevelError("Please select level");
      return;
    }

    if (!programType) {
      setProgramTypeError("Please select program type");
      return;
    }

    if (!selectdApp || isEmpty(selectdApp)) {
      showAlertDialog(true);
      return;
    }

    const tempvalue = { ...selectdApp };
    let isErrorOccured = false;
    for (var key in selectdApp) {
      if (!tempvalue[key]?.license) {
        tempvalue[key]["licenseError"] = "Please enter license count";
        isErrorOccured = true;
      }

      if (!tempvalue[key]?.startDate) {
        tempvalue[key]["startDateError"] = "Please select start date";
        isErrorOccured = true;
      }

      if (!tempvalue[key]?.endDate) {
        tempvalue[key]["endDateError"] = "Please select end date";
        isErrorOccured = true;
      }

      if (!tempvalue[key]?.subscription_type) {
        tempvalue[key]["subscription_type_error"] =
          "Please select subscription type";
        isErrorOccured = true;
      }
    }

    const tempService = ccService;
    for (var service in ccService) {
      if (tempService?.[service]?.serviceCenterName === "") {
        tempService[service]["name_error"] = "Please Enter Service Center Name";
        isErrorOccured = true;
      }
      if (tempService?.[service]?.licenceNumber === "0") {
        tempService[service]["licenseError"] =
          "Please Enter number of licences";
        isErrorOccured = true;
      }
      if (tempService?.[service]?.licenceNumber === "") {
        tempService[service]["licenseError"] =
          "Please Enter number of licences";
        isErrorOccured = true;
      }
    }

    if (isErrorOccured) {
      setSelectedApps(tempvalue);
      setCcService(tempService);
    } else {
      const body: any = {
        name: name,
        address1: address1,
        address2: address2,
        phone: phone,
        state: state,
        contact1: contact1,
        contact2: contact2,
        pincode: pincode,
        emailId: email,
        country: country.name,
        country_code: country.code,
        country_dial_code: country.dial_code,
        level: level.label,
        programType: programType.label,
        region: region.label,
        contact_person_name: contactPersonName,
        city: city,
      };
      const applications: any = [];
      for (var key in selectdApp) {
        const applicationObj = {
          applicationId: selectdApp[key]?.applicationId,
          license: selectdApp[key]?.license,
          // startDate: selectdApp[key]?.startDate.toISOString().split("T")[0],
          // expireDate: selectdApp[key]?.endDate.toISOString().split("T")[0],
          startDate: moment(selectdApp[key]?.startDate).format("YYYY-MM-DD HH:mm:ss.SSSSSSS"),
          expireDate: moment(selectdApp[key]?.endDate).format("YYYY-MM-DD HH:mm:ss.SSSSSSS"),
          subscription_type: selectdApp[key]?.subscription_type.label,
          code: key,
          // id: 25, Need to add for update
        };
        applications.push(applicationObj);
      }
      body["userApplications"] = applications;
      const corCData: any = [];
      for (var ser in ccService) {
        const serCObj = {
          applicationId: selectdApp["COR-C"]?.applicationId,
          name: ccService[ser].serviceCenterName,
          license: ccService[ser].licenceNumber,
          isDelete: ccService[ser].isDelete,
          isActive: ccService[ser].isActive,
        };
        corCData.push(serCObj);
      }

      body["corCServices"] = corCData;
      dispatch(createOrgnization(body));
    }
  };

  // selectedDate.toISOString().split('T')[0]
  const MyLoader = () => (
    <ContentLoader viewBox="0 0 380 26" className="rounded-lg">
      <rect x="0" y="0" rx="0" ry="0" width="100%" height="60" />
    </ContentLoader>
  );

  const addCCService = () => {
    const generateRandomId = () => {
      const randomId = Math.random().toString(36).substr(2, 9);
      return randomId;
    };

    const newTeacher: any = {
      ccId: generateRandomId(),
      serviceCenterName: "",
      licenceNumber: "0",
      isActive: true,
      isDelete: false,
    };

    setCcService([...ccService, newTeacher]);
  };

  const deleteCCService = (service: any) => {
    const ccNew = ccService.filter((ser: any) => ser.ccId !== service.ccId);
    setCcService(ccNew);
  };

  useEffect(() => {
    if (selectdApp && selectdApp["COR-C"]) {
      let tempSelected = { ...selectdApp };
      let totalLicenses = ccService
        .reduce((sum, service) => sum + parseInt(service.licenceNumber || 0), 0)
        .toString();
      if (tempSelected["COR-C"]) {
        tempSelected["COR-C"]["license"] = totalLicenses;
        setSelectedApps(tempSelected);
      }
    }
  }, [ccService]);

  return (
    <>
      {/* <AuthenticatedTemplate>
        {activeAccount ? ( */}
      <Container className="h-full bg-white p-4 min-w-[80vw] font-Popins overflow-auto">
        <div className="h-full res_height ">
          <div className="flex flex-row justify-center  bg-[#264390] max-w-[280px]  mx-auto text-white font-medium p-2 min-h-fit max-h-full rounded-sm ">
            <BuildingOfficeIcon className="w-8 h-8 mr-2 " />
            <span className="text-2xl font-medium ">Create Organization</span>
          </div>
          <div className="Card my-3 rounded-md shadow-sm bg-[#ececec]">
            <div className="p-4 xl:h-[57vh] 2xl:h-[65vh] 1xl:h-[58vh] lg:h-[55vh] md:h-[55vh] overflow-auto">
              <span className="font-semibold text-xl">
                Enter Organization Details
              </span>
              <div className="grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 px-1 py-3 gap-y-1 gap-x-5">
                <TextInput
                  label={"Name"}
                  placeholder={"Name"}
                  required={true}
                  value={name}
                  error={nameError}
                  onChange={(e: any) => {
                    setName(e.target.value);
                    setNameError("");
                  }}
                />
                <TextInput
                  label={"Contact Person Name"}
                  placeholder={"Contact Person Name"}
                  required={true}
                  value={contactPersonName}
                  error={contactPersonNameError}
                  onChange={(e: any) => {
                    setContactPersonName(e.target.value);
                    setContactPersonNameError("");
                  }}
                />

                <TextInput
                  label={"Contact Person Email"}
                  placeholder={"Contact Person Email"}
                  required={true}
                  error={emailError}
                  value={email}
                  onChange={(e: any) => {
                    setEmail(e.target.value);
                    setEmailError("");
                  }}
                />

                <TextInput
                  label={"Address 1"}
                  placeholder={"Address 1"}
                  required={true}
                  error={address1Error}
                  value={address1}
                  onChange={(e: any) => {
                    setAddress1(e.target.value);
                    setAddress1Error("");
                  }}
                />
                <TextInput
                  required={false}
                  label={"Address 2"}
                  placeholder={"Address 2"}
                  value={address2}
                  onChange={(e: any) => setAddress2(e.target.value)}
                />

                <TextInput
                  label={"City"}
                  placeholder={"City"}
                  required={true}
                  value={city}
                  error={cityError}
                  onChange={(e: any) => {
                    setCity(e.target.value);
                    setCityError("");
                  }}
                />

                <TextInput
                  label={"State"}
                  placeholder={"State"}
                  value={state}
                  error={stateError}
                  required={true}
                  onChange={(e: any) => {
                    setState(e.target.value);
                    setStateError("");
                  }}
                />

                <HSSelect
                  label={"Select Country"}
                  placeholder="Select Country *"
                  options={countryList.data}
                  required={true}
                  getOptionLabel={(option: any) => option.name}
                  getOptionValue={(option: any) => option.code}
                  value={country}
                  onChange={(obj: any) => {
                    setCountry(obj);
                    setCountryError("");
                    // onChangeUserDetails(obj, "organization");
                    // setOrgDetails(obj);
                  }}
                  error={countryError}
                />

                <TextInput
                  label={"Zip Code"}
                  placeholder={"Zip Code"}
                  required={true}
                  value={pincode}
                  error={pincodeError}
                  onChange={(e: any) => {
                    const re = /^[a-zA-Z0-9\b]+$/;
                    if (
                      (re.test(e.target.value) && e.target.value.length < 10) ||
                      !e.target.value
                    ) {
                      setPincode(e.target.value);
                      setPincodeError("");
                    }
                  }}
                />

                <TextInput
                  label={"Phone"}
                  placeholder={"Phone"}
                  required={true}
                  value={phone}
                  error={phoneError}
                  onChange={(e: any) => {
                    const re = /^[0-9\b]+$/;
                    if (
                      (re.test(e.target.value) && e.target.value.length < 11) ||
                      !e.target.value
                    ) {
                      setPhone(e.target.value);
                      setPhoneError("");
                    }
                  }}
                />
                {/* <TextInput
                  placeholder={"Country"}
                  required={true}
                  value={country}
                  error={countryError}
                  onChange={(e: any) => {
                    setCountry(e.target.value);
                    setCountryError("");
                  }}
                /> */}

                <TextInput
                  label={"Contact 1"}
                  required={true}
                  placeholder={"Contact 1"}
                  value={contact1}
                  onChange={(e: any) => setContact1(e.target.value)}
                />
                <TextInput
                  required={false}
                  label={"Contact 2 "}
                  placeholder={"Contact 2"}
                  value={contact2}
                  onChange={(e: any) => setContact2(e.target.value)}
                />

                <HSSelect
                  label={"Select Region"}
                  required={true}
                  placeholder="Select Region *"
                  options={ORGNIZATION_REGION}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                  value={region}
                  onChange={(obj: any) => {
                    setRegion(obj);
                    setRegionError("");
                    // onChangeUserDetails(obj, "organization");
                    // setOrgDetails(obj);
                  }}
                  error={regionError}
                />

                <HSSelect
                  label={"Select Level"}
                  required={true}
                  placeholder="Select Level *"
                  options={ORGNIZATION_LEVEl}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                  value={level}
                  onChange={(obj: any) => {
                    setLevel(obj);
                    setLevelError("");
                  }}
                  error={levelError}
                />

                <HSSelect
                  label={"Select Program type"}
                  required={true}
                  placeholder="Select Program type *"
                  options={ORGNIZATION_PROGRAM_TYPE}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                  value={programType}
                  onChange={(obj: any) => {
                    setProgramType(obj);
                    setProgramTypeError("");
                    // onChangeUserDetails(obj, "organization");
                    // setOrgDetails(obj);
                  }}
                  error={programTypeError}
                />
              </div>

              <div className="grid grid-cols-3 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 px-1 py-3 gap-4">
                <div />
                <div />
                {!application.appLoading ? (
                  <>
                    {application.appList.length < 8 && (
                      <Menu>
                        <MenuHandler>
                          <Button
                            className="  flex flex-row items-center  bg-[#264390]  p-2 h-[46px]"
                            size="sm"
                            // disabled={!isValidUserDetails()}
                            // // color="blue"
                            onClick={() => addApplication()}
                          >
                            <ChevronDownIcon className="w-7 h-7 mr-2 " />
                            <span className="text-[15px] font-normal">
                              {" "}
                              Add Application Access{" "}
                            </span>
                          </Button>
                        </MenuHandler>

                        <MenuList
                          className={` p-0 w-[24%] relative  bg-[#264390] text-white boredr border-white rounded-none -mt-2 `}
                        >
                          {application.appList.map(
                            (menu: any, index: number) => (
                              <MenuItem
                                key={index}
                                className={`border-b border-white rounded-none 
                      ${
                        isSelected(menu.applicationCode) === -1
                          ? " "
                          : "bg-gray-200 text-gray-600 rounded-none "
                      }`}
                                onClick={() => onSelectOption(menu, "ADD")}
                              >
                                {menu.applicationName}
                              </MenuItem>
                            )
                          )}
                        </MenuList>
                      </Menu>
                    )}
                  </>
                ) : (
                  <MyLoader />
                )}
              </div>

              {applications.map((obj: any, index: number) => {
                let isError = false;
                let isCompleted = false;
                if (
                  selectdApp[obj.code]?.licenseError ||
                  selectdApp[obj.code]?.startDateError ||
                  selectdApp[obj.code]?.endDateError ||
                  selectdApp[obj.code]?.subscription_type_error
                ) {
                  isError = true;
                } else {
                  if (
                    selectdApp[obj.code]?.license &&
                    selectdApp[obj.code]?.startDate &&
                    selectdApp[obj.code]?.endDate &&
                    selectdApp[obj.code]?.subscription_type
                  ) {
                    isCompleted = true;
                  }
                }
                return (
                  <Accordion
                    open={open === index}
                    className="mb-2 rounded-lg border border-blue-gray-100 text-center shadow-xl "
                  >
                    <AccordionHeader
                      onClick={() => toggleHeader(index)}
                      className={`bg-[#264390] text-white ${
                        open == index
                          ? "rounded-lg rounded-b-none"
                          : "rounded-lg "
                      } h-[40px]  flex justify-between`}
                      //   className={`border-b-0 transition-colors
                      //    ${
                      //     open === 1 ? "text-blue-500 hover:!text-blue-700" : ""
                      //   }`
                      // }
                    >
                      {/* <p className="w-[95%] mt-3 px-3">{obj.headerTitle}</p>
                    <ChevronDownIcon className="w-8 h-8 " /> */}

                      <div className=" w-full flex flex-row justify-between items-center">
                        <div className="flex flex-row items-center">
                          <p className="mt-3 px-4">{obj.headerTitle}</p>
                          {isError && (
                            <ExclamationCircleIcon
                              className="w-8 h-8"
                              color={"#f2382e"}
                            />
                          )}
                          {isCompleted && (
                            <CheckBadgeIcon
                              className="w-8 h-8"
                              color={"#07e015"}
                            />
                          )}
                        </div>
                        <div className=" flex flex-row">
                          <div className="flex text-white">
                            <PencilSquareIcon className="w-8 h-8 mr-3 bg-[#264390] p-1" />
                            <TrashIcon
                              className="w-8 h-8 bg-[#264390] p-1"
                              onClick={() => onSelectOption(obj.id, "DELETE")}
                            />
                          </div>
                          <ChevronDownIcon className="w-8 h-8 ml-2 items-end" />
                        </div>
                      </div>
                    </AccordionHeader>
                    <AccordionBody className="pt-0 text-base font-normal bg-white">
                      <div className="flex flex-row w-full items-center">
                        <Image
                          className="px-3 py-2"
                          // src={element.imageUrl}
                          src={obj.logo}
                          width={180}
                          height={72}
                          alt="COR Advantage"
                        />

                        <div className="grid grid-cols-4 lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-4 px-5 py-3 w-full ">
                          <TextInput
                            placeholder="Licence"
                            required={true}
                            value={selectdApp[obj.code]?.license}
                            crossOrigin={undefined}
                            type="number"
                            label="Licence"
                            error={selectdApp[obj.code]?.licenseError}
                            disabled={
                              obj.code === "COR-C" && ccService.length > 0
                            }
                            // value={application.role}
                            // onChange={(e) => onChangeValue(e.target.value, 'role', index)}
                            onChange={(e: any) => {
                              applications[index].role = e.target.value;
                              setApplications([...applications]);
                              // key: string, chiledkey: string, value: any

                              onValunChange(
                                obj.code,
                                "license",
                                e.target.value
                              );
                            }}
                          />
                          <div className="flex flex-col text-left">
                            <div className="w-full flex text-left">
                              <div className="text-red-600">*</div>
                              Start Date
                            </div>

                            <DatePicker
                              className="border-2 w-full h-[45px] px-2  outline-blue-500 border-[#264390] rounded-lg bg-transparent undefined required-field"
                              selected={selectdApp[obj.code]?.startDate}
                              onChange={(date: any) => {
                                onValunChange(obj.code, "startDate", date);

                                const d = new Date(date);
                                d.setFullYear(d.getFullYear() + 1);
                                onValunChange(obj.code, "endDate", d);
                              }}
                              placeholderText="MM/DD/YYYY"
                              // dateFormat="yyyy-MM-dd"
                              // showIcon={true}
                              // icon={
                              //   <CalendarMonthOutlinedIcon className="w-16 h-16 mt-[2px] items-end ml-[90%]" />
                              // }
                              title={"Start Date"}
                              required={true}
                            />
                            {selectdApp[obj.code]?.startDateError ? (
                              <div className={"text-xs text-[#FF0000] flex"}>
                                {selectdApp[obj.code]?.startDateError}
                              </div>
                            ) : (
                              <div className={"text-xs text-[#FF0000] h-4"} />
                            )}
                          </div>

                          <div className="flex flex-col">
                            <div className="w-full flex ">
                              <div className="text-red-600">*</div>
                              Subscription Type
                            </div>
                            <div className="-mt-6 ">
                              <HSSelect
                                placeholder="Subscription Type*"
                                options={SUBSCRIPTION_TYPE}
                                getOptionLabel={(option: any) => option.label}
                                getOptionValue={(option: any) => option.value}
                                value={selectdApp[obj.code]?.subscription_type}
                                onChange={(obj: any) => {
                                  // setRegion(obj);
                                  // setRegionError("");
                                  //
                                  // onChangeUserDetails(obj, "organization");
                                  // setOrgDetails(obj);
                                }}
                                error={
                                  selectdApp[obj.code]?.subscription_type_error
                                }
                              />
                            </div>
                          </div>

                          <div className="w-[100%] flex flex-col">
                            <div className="w-full flex ">
                              <div className="text-red-600">*</div>
                              Expire Date
                            </div>

                            <DatePicker
                              className="border-2 w-full h-[45px] px-2  outline-blue-500 border-[#264390] rounded-lg bg-transparent undefined required-field "
                              selected={selectdApp[obj.code]?.endDate}
                              onChange={(date: any) => {
                                onValunChange(obj.code, "endDate", date);
                              }}
                              placeholderText="MM/DD/YYYY"
                              // dateFormat="yyyy-MM-dd"
                              disabled={!selectdApp[obj.code]?.startDate}
                              minDate={selectdApp[obj.code]?.startDate}
                              // minDate={
                              //   selectdApp[obj.code]?.endDate ||
                              //   selectdApp[obj.code]?.startDate
                              // }
                              // showIcon={true}
                              // icon={
                              //   <CalendarMonthOutlinedIcon className="w-16 h-16 mt-[2px] items-end ml-[90%]" />
                              // }
                              required={true}
                            />
                            {selectdApp[obj.code]?.endDateError ? (
                              <div
                                className={"text-xs text-[#FF0000] ml-2 flex"}
                              >
                                {selectdApp[obj.code]?.endDateError}
                              </div>
                            ) : (
                              <div className={"text-xs text-[#FF0000] h-4"} />
                            )}
                          </div>

                          {/* <TextInput
                          placeholder="Start Date"
                          required={true}
                          // value={application.class}
                          // onChange={(e) => onChangeValue(e.target.value, 'class', index)}
                        />
                        <TextInput
                          placeholder="Expire Date"
                          required={true}
                          crossOrigin={undefined}
                          // value={application.organization}
                          // onChange={(e) => onChangeValue(e.target.value, 'organization', index)}
                        /> */}
                        </div>
                      </div>
                      {obj.code === "COR-C" && (
                        <div className="px-4">
                          <div className="">
                            <Button
                              className="flex flex-row items-center justify-center bg-[#264390] hover:bg-gray-500 py-3 px-5 2xl:px-4 2xl:py-3 rounded-md"
                              onClick={() => addCCService()}
                            >
                              <span className="text-[15px] 2xl:text-[18px] font-semibold hover:text-[#264390]">
                                + Add Service Center (Level 2)
                              </span>
                            </Button>
                          </div>

                          {ccService?.map((service, index) => (
                            <div
                              key={index}
                              className={`${
                                service.isDelete ? "opacity-40" : ""
                              }  border border-[#264390] bg-[#ececec] mt-3 grid grid-cols-3 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-3 items-center `}
                            >
                              <div className="ml-4 mr-4">
                                <TextInput
                                  label={"Service Center Name"}
                                  placeholder={"Service Center Name"}
                                  required={true}
                                  value={service.serviceCenterName}
                                  error={service.name_error}
                                  disabled={service.isDelete}
                                  onChange={(e: any) => {
                                    service.serviceCenterName = e.target.value;
                                    service.name_error = undefined;
                                    setCcService([...ccService]);
                                  }}
                                />
                              </div>
                              <div className="ml-4 mr-4">
                                <TextInput
                                  placeholder="Number of Licences"
                                  required={true}
                                  crossOrigin={undefined}
                                  type="number"
                                  label="Licence"
                                  disabled={service.isDelete}
                                  error={service.licenseError}
                                  value={service.licenceNumber}
                                  // error={nameError}

                                  onChange={(e: any) => {
                                    service.licenceNumber = e.target.value;
                                    service.licenseError = undefined;
                                    setCcService([...ccService]);
                                  }}
                                />
                              </div>

                              {/* <Button
                                className="flex flex-row items-center justify-center ml-4 bg-[#264390] hover:bg-gray-500 py-3 px-5 2xl:px-4 2xl:py-3 rounded-md !capitalize "
                                // onClick={() => onSubmit()}
                              >
                                <span className="text-[15px] 2xl:text-[18px] font-semibold hover:text-[#264390]">
                                  {" "}
                                  Active / Inactive
                                </span>
                              </Button> */}
                              {/* {!service.isDelete && (
                                <Button
                                  className="flex flex-row items-center justify-center m-4 bg-[#264390] hover:bg-gray-500 py-3 px-5 2xl:px-4 2xl:py-3 rounded-md !capitalize "
                                  onClick={() => {
                                    service.isDelete = !service.isDelete;
                                    setCcService([...ccService]);
                                  }}
                                >
                                  <span className="text-[15px] 2xl:text-[18px] font-semibold hover:text-[#264390]">
                                    {" "}
                                    Deactivate
                                  </span>
                                </Button>
                              )}
                              {service.isDelete && (
                                <Button
                                  className="flex flex-row items-center justify-center m-4 bg-[#264390] hover:bg-gray-500 py-3 px-5 2xl:px-4 2xl:py-3 rounded-md !capitalize "
                                  onClick={() => {
                                    service.isDelete = !service.isDelete;
                                    setCcService([...ccService]);
                                  }}
                                >
                                  <span className="text-[15px] 2xl:text-[18px] font-semibold hover:text-[#264390]">
                                    {" "}
                                    Activate
                                  </span>
                                </Button>
                              )} */}

                              <Button
                                className="flex flex-row items-center justify-center m-4 bg-[#264390] hover:bg-gray-500 py-3 px-5 2xl:px-4 2xl:py-3 rounded-md !capitalize "
                                onClick={() => {
                                  deleteCCService(service);
                                }}
                              >
                                <span className="text-[15px] 2xl:text-[18px] font-semibold hover:text-[#264390]">
                                  {" "}
                                  Delete
                                </span>
                              </Button>
                            </div>
                          ))}
                        </div>
                      )}
                    </AccordionBody>
                  </Accordion>
                );
              })}
            </div>

            <div
              className={`flex justify-end px-5 border-t-2 border-gray-400 py-3 h-[60px]`}
            >
              <Button
                className="flex flex-row items-center justify-center bg-[#264390] hover:bg-gray-500 py-3 px-3 2xl:px-4 2xl:py-3 rounded-md"
                onClick={() => onSubmit()}
              >
                <span className="text-[15px] 2xl:text-[18px] font-semibold hover:text-[#264390]">
                  {" "}
                  Register
                </span>
              </Button>
            </div>
          </div>
        </div>
      </Container>
      {/* ) : null}
      </AuthenticatedTemplate> */}

      <HSDialog
        header={"Error"}
        message="Please select at least one application"
        handleOpen={(message: any) => showAlertDialog(message)}
        open={alertDialog}
        size={"xs"}
      />
    </>
  );
};
