import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Container } from "react-bootstrap";
import "react-data-grid/lib/styles.css";

import { Button } from "@material-tailwind/react";
import "../components/DataTable/styles.css";
import { useEffect, useState } from "react";
import { RootState } from "../redux/store";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../hooks/app";
import {
  deleteOrgnization,
  getAllOrgnization,
  recoverOrgnization,
} from "../redux/actions/orgnization";
import GridTable from "../components/Table";
import { useNavigate } from "react-router-dom";
import BootstrapModal from "../components/BootstrapModal";
import moment from "moment-timezone";
import ConfirmationDialog from "../components/Dialogs/ConfirmationDialog";
import NotesModal from "../components/Table/NotesModal";

export const OrgnizationList = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const organization = useSelector((state: RootState) => state.organization);
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useAppDispatch();

  const [rows, setRows] = useState<any>([]);
  const [limit, setLimit] = useState<any>(10);
  const [page, setPage] = useState<any>(1);
  const [searchString, setSearchString] = useState<any>("");
  const [modalType, setModalType] = useState<string>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [orgData, setOrgData] = useState<any>(null);
  const [licenseData, setLicenseData] = useState<any>([]);
  const [confirmDialog, setConfirmDialog] = useState<any>(false);
  const [recoverConfirmDialog, setRecoverConfirmDialog] = useState<any>(false);
  const [selectedOrgnization, setSelectedOrgnization] = useState<any>(false);
  const [orgId, setOrgId] = useState<any>(0);
  const navigate = useNavigate();
  // const[totalRecords,setTotalRecords]=useState<number>(0)

  // const getLicenceDetails = () => {
  //   return (
  //     <div className="flex self-center justify-center h-full items-center pt-3 ">
  //     <p className="px-1 " >
  //       <EyeIcon
  //         onClick={() => onClickAction && onClickAction("View", id)}
  //         className="h-7 w-7 text-blue-800" />
  //     </p>
  //   </div>
  //   );
  // };

  // useEffect(() => {
  //   if (user.userDetails) {
  //     getAllOrgnizationList();
  //   }
  // }, [user.userDetails]);

  useEffect(() => {
    if (organization.orgList && organization.orgList.length > 0) {
      const orgList = [];
      for (let i = 0; i < organization.orgList.length; i++) {
        const orgObj = organization.orgList[i];
        const obj = {
          ...orgObj,
        };
        orgList.push(obj);
      }
      // setTotalRecords(organization?.orgList)
      setRows(orgList);
    } else {
      setRows([]);
    }
  }, [organization.orgList]);

  // const filterTableData=(tableData=[])=>{

  //   const filteredData= tableData.map((item)=>{
  //     const  leaderFullName=item.leader?.firstName+" "+item.leader?.lastName
  //       return({...item,leaderName:leaderFullName})
  //     })
  //     setTableData(filteredData)
  // }

  // const getAllOrgnizationList = () => {

  //   dispatch(getAllOrgnization());
  // };

  const columns: any = [
    { key: "name", name: "Organization Name", sortable: true, draggable: true },
    {
      key: "contact_person_name",
      name: "Contact Person",
      sortable: true,
      draggable: true,
    },
    {
      key: "emailId",
      name: "Contact Email",
      sortable: true,
      draggable: true,
    },
    {
      key: "phone",
      name: "Contact Phone",
      sortable: true,
      draggable: true,
    },
    {
      key: "region",
      name: "Region",
      sortable: true,
      draggable: true,
    },
    {
      key: "view",
      name: "License Detail",
    },
  ];

  const column1: any = [
    {
      key: "appName",
      name: "Application Name",
      sortable: true,
      draggable: true,
    },
    {
      key: "appCode",
      name: "Application Code",
      sortable: true,
      draggable: true,
    },
    { key: "startDate", name: "Start Date", sortable: true, draggable: true },
    { key: "endDate", name: "End Date", sortable: true, draggable: true },
    { key: "license", name: "License", sortable: true, draggable: true },
  ];

  const column2: any = [
    { key: "full_name", name: "Name" },
    { key: "email", name: "Email" },
  ];

  const onClickAction = (type: string, rowData: any) => {
    const id = rowData?.id || rowData?._id;
    if (rowData?.user) {
      const newUser = rowData.user.map((userData: any) => ({
        ...userData,
        full_name: userData.firstName + " " + userData.lastName,
      }));
      rowData.user = newUser;
    }

    if (type == "Edit") {
      navigate("/editOrgList", { state: { rowData } });
    } else if (type == "Delete") {
      setSelectedOrgnization(rowData);
      setConfirmDialog(true);
    } else if (type == "View") {
      setModalType("View");
      setOpenModal(true);
      setOrgData(rowData);
      licenseFilterData(rowData);
    } else if (type === "Recover") {
      setSelectedOrgnization(rowData);
      setRecoverConfirmDialog(true);
    } else if (type === "Notes") {
      setOrgId(id);
      setModalType("Notes");
      setOpenModal(true);
    }
  };
  const licenseFilterData = (orgData: any) => {
    const dataLicense = orgData?.license?.map((row: any) => {
      const appName = row?.application?.applicationName;
      const appCode = row?.application?.applicationCode;
      const startDate = moment(row.startDate).format("MM/DD/YYYY");
      const endDate = moment(row.expireDate).format("MM/DD/YYYY");
      return { ...row, appName, appCode, startDate, endDate };
    });
    setLicenseData(dataLicense);
  };

  const getList = () => {
    const data: any = {
      page: page,
      limit: limit,
      // orgId: orgId,
      searchParam: searchString ? searchString : "",
    };
    // dispatch(getUserList(data));
    dispatch(getAllOrgnization(data));
  };

  useEffect(() => {
    getList();
  }, [page, limit, searchString]);

  // const handleSearchString = (key:any) => {
  //   if (key.length <= 0) {
  //     getList(page, "all");
  //   }
  // };

  const onDeleteOrg = () => {
    setConfirmDialog(false);
    dispatch(deleteOrgnization(selectedOrgnization.id));
  };

  const onRecoverOrg = () => {
    setRecoverConfirmDialog(false);
    dispatch(recoverOrgnization(selectedOrgnization.id));
  };

  return (
    <AuthenticatedTemplate>
      {activeAccount ? (
        <>
          <Container className="h-full bg-white p-4 min-w-[75vw] font-['poppins'] overflow-auto">
            <div className="xl:h-[57vh] 2xl:h-[65vh] 1xl:h-[58vh] lg:h-[55vh] md:h-[55vh] ">
              <GridTable
                rowData={rows}
                columnData={columns}
                onClickAction={onClickAction}
                showAction={true}
                onPageChange={(pageNumber) => setPage(pageNumber)}
                onRowsPerPageChange={(limit) => setLimit(limit)}
                totalCount={organization.totalCount}
                onClickSearch={(searchKey) => {
                  setPage(1);
                  setSearchString(searchKey);
                }}
                showNote={true}
              />
            </div>

            {modalType === "View" && (
              <BootstrapModal
                isVisble={openModal}
                handleClose={() => setOpenModal(false)}
                modalHeader={
                  <>
                    <div className="flex justify-between content-between w-full mx-4  mt-1  font-['poppins']  pt-2">
                      <p className="font-normal text-xl">
                        Organization :
                        <span className="font-medium">
                          {"  " + orgData?.name}{" "}
                        </span>{" "}
                      </p>
                    </div>
                  </>
                }
                modalBody={
                  <>
                    <div>
                      <div className="min-h-[300px]">
                        <p className="text-[#264390] font-bold text-[20px] flex justify-center">
                          License Details
                        </p>

                        <GridTable
                          rowData={licenseData}
                          columnData={column1}
                          // onClickAction={onClickAction}
                          showAction={false}
                          onPageChange={(pageNumber) => setPage(pageNumber)}
                          onRowsPerPageChange={(limit) => setLimit(limit)}
                          totalCount={licenseData.length}
                          // onClickSearch={(searchKey) => {
                          //   setPage(1);
                          //   setSearchString(searchKey);
                          // }}
                          hidePagination={true}
                          hideSearch={true}
                        />
                      </div>
                      <div>
                        <p className="text-[#264390] font-bold text-[20px] mt-5 flex justify-center">
                          User Details
                        </p>

                        <GridTable
                          rowData={orgData?.user}
                          columnData={column2}
                          // onClickAction={onClickAction}
                          showAction={false}
                          onPageChange={(pageNumber) => setPage(pageNumber)}
                          onRowsPerPageChange={(limit) => setLimit(limit)}
                          totalCount={orgData?.user?.length}
                          // onClickSearch={(searchKey) => {
                          //   setPage(1);
                          //   setSearchString(searchKey);
                          // }}
                          // hidePagination={false}
                          hideSearch={true}
                        />
                      </div>
                    </div>
                  </>
                }
                footerBody={
                  <>
                    <div className=" rounded-md text-[#264390] mr-2 ">
                      <Button
                        className="bg-white text-[18px] py-1 px-8 "
                        variant="gradient"
                        color="white"
                        type="submit"
                        onClick={() => {
                          setOpenModal(false);
                          setModalType("");
                        }}
                      >
                        Close
                      </Button>
                    </div>
                  </>
                }
              />
            )}
            {modalType === "Notes" && (
              <NotesModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                setModalType={setModalType}
                orgId={orgId}
              />
            )}

            {selectedOrgnization && confirmDialog && (
              <ConfirmationDialog
                open={confirmDialog}
                message={`Are you sure? you want to delete "${selectedOrgnization.name}" orgnization, It may affect all user, All user won't be able to login to any application from "${selectedOrgnization.name}" orgnization`}
                onConfirm={() => onDeleteOrg()}
                onCancel={() => setConfirmDialog(false)}
              />
            )}
            {selectedOrgnization && recoverConfirmDialog && (
              <ConfirmationDialog
                open={recoverConfirmDialog}
                message={`Are you sure? you want to recover "${selectedOrgnization.name}" orgnization.`}
                onConfirm={() => onRecoverOrg()}
                onCancel={() => setRecoverConfirmDialog(false)}
              />
            )}
          </Container>
        </>
      ) : null}
    </AuthenticatedTemplate>
  );
};
