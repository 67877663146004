import React, { useCallback, useEffect, useRef, useState } from "react";
import BootstrapModal from "../BootstrapModal";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import {
  createNote,
  getAllNotes,
  updateNote,
  deleteNote,
} from "../../redux/actions/orgnization";
import { useAppDispatch } from "../../hooks/app";
import { RootState } from "../../redux/store";
import { TrashIcon } from "@heroicons/react/24/outline";
import { Button } from "@material-tailwind/react";

interface NotesModalProps {
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
  setModalType: (type: string) => void;
  orgId: number; // Add orgId prop
}

interface Note {
  id: number;
  title: string;
  description: string;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

const NotesModal: React.FC<NotesModalProps> = ({
  openModal,
  setOpenModal,
  setModalType,
  orgId,
}) => {
  const dispatch = useAppDispatch();
  const noteRef = useRef<HTMLDivElement>(null);

  const [description, setDescription] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [title, setTitle] = useState("");
  const [selectedNote, setSelectedNote] = useState<Note | null>(null);
  const [notesList, setNotesList] = useState<Note[]>([]);

  const notesData = useSelector(
    (state: RootState) => state.organization.notes || []
  );

  const handleSave = useCallback(async () => {
    if (title || description) {
      const data = { title, description, orgId };
      try {
        let response;
        if (selectedNote) {
          response = await dispatch(updateNote(selectedNote.id, data));
          Swal.fire({
            title: "Updated!",
            text: "Your note has been updated.",
            icon: "success",
            timer: 2000,
            showConfirmButton: false,
          });
        } else {
          response = await dispatch(createNote(orgId, data));
          Swal.fire({
            title: "Created!",
            text: "Your note has been created.",
            icon: "success",
            timer: 2000,
            showConfirmButton: false,
          });
        }
        if (response?.success) {
          dispatch(getAllNotes(orgId));
          resetNote();
        }
      } catch (error) {
        Swal.fire("Error!", "There was an error saving your note.", "error");
      }
    } else {
      Swal.fire("Warning!", "Title or description cannot be empty.", "warning");
    }
  }, [title, description, selectedNote, dispatch, orgId]);

  const handleDelete = useCallback(
    async (id: number) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await dispatch(deleteNote(id));
            dispatch(getAllNotes(orgId));
            Swal.fire({
              title: "Deleted!",
              text: "Your note has been deleted.",
              icon: "success",
              timer: 2000,
              showConfirmButton: false,
            });
            resetNote();
          } catch (error) {
            Swal.fire(
              "Error!",
              "There was an error deleting your note.",
              "error"
            );
          }
        }
      });
    },
    [dispatch, orgId]
  );

  useEffect(() => {
    if (openModal) {
      dispatch(getAllNotes(orgId));
    }
  }, [dispatch, orgId, openModal]);

  useEffect(() => {
    setNotesList(notesData);
  }, [notesData]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (noteRef.current && !noteRef.current.contains(event.target as Node)) {
        setIsExpanded(false);
        resetNote();
      }
    }
    if (isExpanded) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isExpanded]);

  const resetNote = (event?: React.MouseEvent) => {
    if (event) event.stopPropagation();
    setTitle("");
    setDescription("");
    setSelectedNote(null);
    setIsExpanded(false);
  };

  const formatDate = (dateString: string) => {
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    }).format(new Date(dateString));
  };

  return (
    <div>
      <BootstrapModal
        isVisble={openModal}
        handleClose={() => setOpenModal(false)}
        modalHeader={
          <div className="flex justify-between w-full mx-4 mt-1 font-['poppins'] pt-2">
            <p className="font-normal text-xl">Notes</p>
          </div>
        }
        modalBody={
          <div className="p-4">
            <div className="w-full mb-6 flex justify-center">
              <div
                className={`w-full sm:max-w-lg border rounded-lg p-3 shadow-sm transition-all relative ${
                  isExpanded ? "shadow-md" : "cursor-text"
                }`}
                onClick={() => setIsExpanded(true)}
                ref={noteRef}
              >
                {isExpanded ? (
                  <>
                    <input
                      className="w-full text-lg font-semibold outline-none border-b border-gray-300 mb-2 pb-1"
                      type="text"
                      placeholder="Title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      autoFocus
                    />

                    <textarea
                      className="w-full min-h-[100px] outline-none resize-none text-[16px] font-medium"
                      placeholder="Take a note..."
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />

                    <div className="flex justify-end mt-2">
                      <button
                        className="bg-blue-500 text-white font-semibold text-sm hover:bg-blue-600 px-4 py-2 rounded"
                        onClick={handleSave}
                      >
                        Save
                      </button>
                      <button
                        className="bg-gray-500 text-white font-semibold text-sm hover:bg-gray-600 px-4 py-2 rounded ml-2"
                        onClick={resetNote}
                      >
                        Cancel
                      </button>
                    </div>
                  </>
                ) : (
                  <p className="text-gray-500">Take a note...</p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 w-full">
              {notesList.map((item, index) => (
                <div
                  className="bg-white border border-gray-200 rounded-xl shadow-md p-4 cursor-pointer transition-all hover:shadow-lg"
                  key={index}
                  onClick={() => {
                    setSelectedNote(item);
                    setTitle(item.title);
                    setDescription(item.description);
                    setIsExpanded(true);
                  }}
                >
                  <p className="text-xl font-semibold text-gray-800 truncate">
                    {item.title}
                  </p>
                  <p className="mt-2 text-gray-600 overflow-hidden text-ellipsis">
                    {item.description}
                  </p>
                  <div className="flex items-center justify-between mt-4 text-gray-600">
                    <span>{formatDate(item.createdAt)}</span>
                    <TrashIcon
                      className="h-6 w-6 text-red-600"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(item.id);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        }
        footerBody={
          <>
            <div className=" rounded-md text-[#264390] mr-2 ">
              <Button
                className="bg-white text-[18px] py-1 px-8 "
                variant="gradient"
                color="white"
                type="submit"
                onClick={() => {
                  setOpenModal(false);
                  setModalType("");
                }}
              >
                Close
              </Button>
            </div>
          </>
        }
      />
    </div>
  );
};

export default NotesModal;
