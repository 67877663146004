import { useEffect, useState } from "react";
import { useAppDispatch } from "../hooks/app";
import { Container, Image } from "react-bootstrap";
import countryList from "../utils/countrylist.json";
import {
  BuildingOfficeIcon,
  ChevronDownIcon,
  PencilSquareIcon,
  TrashIcon,
  ArrowUturnDownIcon,
} from "@heroicons/react/24/outline";
import {
  ORGNIZATION_LEVEl,
  ORGNIZATION_PROGRAM_TYPE,
  ORGNIZATION_REGION,
  SUBSCRIPTION_TYPE,
} from "../utils/constant";
import {
  CheckBadgeIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid";
import HSSelect from "../components/Select";
import ContentLoader from "react-content-loader";
import DatePicker from "react-datepicker";
import TextInput from "../components/Input";
import { RootState } from "../redux/store";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Button,
  Card,
  Checkbox,
  Input,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Option,
  Select,
  Typography,
} from "@material-tailwind/react";
import { useSelector } from "react-redux";
import { getAllApplications } from "../redux/actions/application";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ConfirmationDialog from "../components/Dialogs/ConfirmationDialog";
import { validateEmailId } from "../utils";
import { isEmpty } from "lodash";
import {
  createOrgnizationLog,
  resetUpdateOrg,
  updateOrgnization,
} from "../redux/actions/orgnization";
import HSDialog from "../components/Dialog";
import moment from "moment-timezone";

export const EditOrgnization = () => {
  const loc = useLocation();
  const navigate = useNavigate();
  const rowData = loc?.state?.rowData;
  const [name, setName] = useState(rowData?.name);
  const [nameError, setNameError] = useState("");
  const [address1, setAddress1] = useState(rowData?.address1);
  const [address1Error, setAddress1Error] = useState("");
  const [address2, setAddress2] = useState(rowData?.address2);
  const [contactPersonName, setContactPersonName] = useState(
    rowData?.contact_person_name
  );
  const [contactPersonNameError, setContactPersonNameError] = useState("");
  const [city, setCity] = useState(rowData?.city);
  const [cityError, setCityError] = useState("");
  const [phone, setPhone] = useState(rowData?.phone);
  const [phoneError, setPhoneError] = useState("");
  const [country, setCountry] = useState<any>({
    name: rowData.country,
    code: rowData.country_code,
    dial_code: rowData.country_dial_code,
  });
  const [countryError, setCountryError] = useState("");
  const [region, setRegion] = useState<any>({
    label: rowData?.region,
    value: rowData?.region,
  });
  const [regionError, setRegionError] = useState<any>("");
  const [level, setLevel] = useState<any>({
    label: rowData?.level,
    value: rowData?.level,
  });
  const [levelError, setLevelError] = useState("");
  const [programType, setProgramType] = useState<any>(
    rowData?.programType
      ? { label: rowData?.programType, value: rowData?.programType }
      : undefined
  );
  const [initialData, setInitialData] = useState<any>(rowData ? rowData : {});
  const [programTypeError, setProgramTypeError] = useState("");
  const [state, setState] = useState(rowData?.state);
  const [stateError, setStateError] = useState("");
  const [contact1, setContact1] = useState(rowData?.contact1);
  const [contact2, setContact2] = useState(rowData?.contact2);
  const [pincode, setPincode] = useState(rowData?.pincode);
  const [pincodeError, setPincodeError] = useState("");
  const [email, setEmail] = useState(rowData?.emailId);
  const [emailError, setEmailError] = useState("");
  const [alertDialog, showAlertDialog] = useState<boolean | null>(null);
  const [warningDialog, setWarningDialog] = useState(false);
  const [selectedOrgApp, setSelectedOrgApp] = useState<any>(undefined);

  const application = useSelector((state: RootState) => state.application);
  const org = useSelector((state: RootState) => state.organization);

  const [applications, setApplications] = useState<Array<any>>([]);
  const [open, setOpen] = useState<number>(1);
  const [selectdApp, setSelectedApps] = useState<any>({});
  const [ccService, setCcService] = useState<Array<any>>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllApplications());
  }, []);

  const toggleHeader = (value: number, isExisting?: boolean) => {
    if (!isExisting) {
      if (open === value) {
        handleOpen(-1);
      } else {
        handleOpen(value);
      }
    } else {
      if (openExisting === value) {
        handleOpen(-1, true);
      } else {
        handleOpen(value, true);
      }
    }
  };

  const onValunChange = (key: string, chiledkey: string, value: any) => {
    let tempSelected: any = { ...selectdApp };
    tempSelected[key][chiledkey] = value;
    delete tempSelected[key][chiledkey + "Error"];

    if (chiledkey === "startDate") {
      const startDate = new Date(value);
      tempSelected[key]["endDate"] = new Date(
        startDate.getFullYear() + 1,
        startDate.getMonth(),
        startDate.getDate()
      );
    }
    setSelectedApps(tempSelected);
  };

  const addApplication = () => {
    const temAppList = [...applications];
    setApplications(temAppList);
  };
  const isSelected = (menuId: any) => {
    return selectdApp.hasOwnProperty(menuId);
  };
  const initialApplication = {
    id: 0,
    headerTitle: "",
    logo: "",
    applicationType: "",
    role: "",
    class: "",
    organization: "",
    code: "",
  };

  const [openExisting, setOpenExisting] = useState(0);
  const handleOpen = (value: number, isExisting?: boolean) =>
    isExisting
      ? setOpenExisting(openExisting === value ? 0 : value)
      : setOpen(open === value ? 0 : value);

  const menuList = [
    {
      id: 1,
      name: "COR Advantage",
      logo: "/images/coreA.webp",
      applicationCode: "COR-A",
    },
    {
      id: 2,
      name: "COR FOR Kindergarten",
      logo: "/images/cork.webp",
      applicationCode: "COR-K",
    },
    {
      id: 3,
      name: "Classroom Coach",
      logo: "/images/classroomCoach.webp",
      applicationCode: "COR-C",
    },
    {
      id: 4,
      name: "Ready School Assessment",
      logo: "/images/logoRSA.webp",
      applicationCode: "COR-R",
    },
    {
      id: 5,
      name: "Letter Links",
      logo: "/images/letterLink.webp",
      applicationCode: "COR-L",
    },
    {
      id: 6,
      name: "Program Quality Assessment",
      logo: "/images/logoPQA.webp",
      applicationCode: "COR-P",
    },
    {
      id: 7,
      name: "Curriculum",
      logo: "/images/letterLink.webp",
      applicationCode: "COR-CU",
    },
  ];

  // const isAlreadyAdded = (option: any) => {
  //   const flag =
  //     apps.filter((ele: any) => ele?.application?.id === option?.id).length ===
  //     0;
  //   !flag &&
  //     handleOpen(
  //       apps.findIndex((ele: any) => ele?.application?.id === option?.id),
  //       true
  //     );
  //   return flag;
  // };

  const onSelectOption = (menu: any) => {
    const temAppList = { ...selectdApp };
    if (!temAppList.hasOwnProperty(menu.applicationCode)) {
      const obj = {
        startDate: undefined,
        endDate: undefined,
        license: "",
        applicationId: menu.id,
        isDelete: false,
        applicationName: menu.applicationName,
        code: menu.applicationCode,
        subscription_type: { label: "Yearly", value: "Yearly" },
      };
      temAppList[menu.applicationCode] = obj;
      setSelectedApps(temAppList);
    } else if (
      temAppList.hasOwnProperty(menu.applicationCode) &&
      temAppList[menu.applicationCode].isDelete
    ) {
      temAppList[menu.applicationCode].isDelete = false;
      setSelectedApps(temAppList);
    }
  };

  const compareObjects = (initialData: any, finalData: any) => {
    const changes: any = {
      organizationId: initialData.id,
      changes: [],
    };

    // Compare fields: name, emailId, region, level, programType
    const fieldsToCheck = ["name", "emailId", "region", "level", "programType"];
    fieldsToCheck.forEach((field) => {
      if (initialData[field] !== finalData[field]) {
        changes.changes.push({
          applicationId: null,
          fieldChanged: field,
          oldValue: initialData[field],
          newValue: finalData[field],
          isAdded: false,
          isDeleted: false,
        });
      }
    });

    const initialApps = initialData.license || [];
    const finalApps = finalData.userApplications || [];

    const appChanges: any[] = [];
    finalApps.forEach((finalApp: any) => {
      const initialApp = initialApps.find(
        (app: any) => app.application.id === finalApp.applicationId
      );
      if (initialApp) {
        if (initialApp.license !== finalApp.license) {
          appChanges.push({
            applicationId: finalApp.applicationId,
            fieldChanged: "license",
            oldValue: initialApp.license.toString(),
            newValue: finalApp.license,
            isAdded: false,
            isDeleted: false,
          });
        }
        if (initialApp.startDate.split("T")[0] !== finalApp.startDate) {
          appChanges.push({
            applicationId: finalApp.applicationId,
            fieldChanged: "startDate",
            oldValue: initialApp.startDate.split("T")[0],
            newValue: finalApp.startDate,
            isAdded: false,
            isDeleted: false,
          });
        }
        if (initialApp.expireDate.split("T")[0] !== finalApp.expireDate) {
          appChanges.push({
            applicationId: finalApp.applicationId,
            fieldChanged: "expireDate",
            oldValue: initialApp.expireDate.split("T")[0],
            newValue: finalApp.expireDate,
            isAdded: false,
            isDeleted: false,
          });
        }
      } else {
        appChanges.push({
          applicationId: finalApp.applicationId,
          fieldChanged: null,
          oldValue: null,
          newValue: null,
          isAdded: true,
          isDeleted: false,
        });
      }
    });

    initialApps.forEach((initialApp: any) => {
      const isDeleted = !finalApps.some(
        (finalApp: any) =>
          finalApp.applicationId === initialApp.application.id &&
          finalApp.isDelete === false
      );

      if (isDeleted) {
        appChanges.push({
          applicationId: initialApp.application.id,
          fieldChanged: null,
          oldValue: null,
          newValue: null,
          isAdded: false,
          isDeleted: true,
        });
      }
    });

    if (appChanges?.length > 0) {
      changes.changes = changes.changes.concat(appChanges);
    }

    return changes;
  };
  const addCCService = () => {
    const generateRandomId = () => {
      const randomId = Math.random().toString(36).substr(2, 9);
      return randomId;
    };

    const newTeacher: any = {
      ccId: generateRandomId(),
      serviceCenterName: "",
      licenceNumber: "0",
      isActive: true,
      isDelete: false,
    };

    setCcService([...ccService, newTeacher]);
  };

  useEffect(() => {
    if (selectdApp && selectdApp["COR-C"]) {
      if (ccService?.length > 0) {
        let tempSelected = { ...selectdApp };
        let totalLicenses = ccService
          .filter((service) => service.isActive)
          .reduce(
            (sum, service) => sum + parseInt(service.licenceNumber || 0),
            0
          )
          .toString();
        if (tempSelected["COR-C"]) {
          tempSelected["COR-C"]["license"] = totalLicenses;
          setSelectedApps(tempSelected);
        }
      }
    }
  }, [ccService]);

  const onSubmit = async () => {
    if (!name) {
      setNameError("Please enter name");
      return;
    }

    if (!contactPersonName) {
      setContactPersonNameError("Please enter contact person name");
      return;
    }

    if (!email) {
      setEmailError("Please enter email");
      return;
    }

    if (!validateEmailId(email)) {
      setEmailError("Please enter valid email");
      return;
    }

    if (!address1) {
      setAddress1Error("Please enter address");
      return;
    }

    if (!city) {
      setCityError("Please enter city");
      return;
    }

    if (!state) {
      setStateError("Please enter state");
      return;
    }

    if (!country) {
      setCountryError("Please select country");
      return;
    }

    if (!pincode) {
      setPincodeError("Please enter pincode");
      return;
    }

    if (!phone) {
      setPhoneError("Please enter phone");
      return;
    }

    if (phone && phone.length !== 10) {
      setPhoneError("Phone number must be 10 digit");
      return;
    }

    if (!region) {
      setRegionError("Please select region");
      return;
    }

    if (!level) {
      setLevelError("Please select level");
      return;
    }

    if (!programType) {
      setProgramTypeError("Please select program type");
      return;
    }

    if (!selectdApp || isEmpty(selectdApp)) {
      showAlertDialog(true);
      return;
    }
    const tempvalue = { ...selectdApp };
    let isErrorOccured = false;
    for (var key in selectdApp) {
      if (!tempvalue[key].license) {
        tempvalue[key]["licenseError"] = "Please enter license count";
        isErrorOccured = true;
      }
      if (tempvalue[key].license === "0") {
        tempvalue[key]["licenseError"] = "Please enter license count";
        isErrorOccured = true;
      }

      if (!tempvalue[key].startDate) {
        tempvalue[key]["startDateError"] = "Please select start date";
        isErrorOccured = true;
      }

      if (!tempvalue[key].endDate) {
        tempvalue[key]["endDateError"] = "Please select end date";
        isErrorOccured = true;
      }

      if (!tempvalue[key].subscription_type) {
        tempvalue[key]["subscription_type_error"] =
          "Please select subscription type";
        isErrorOccured = true;
      }
    }

    const tempService = ccService;
    for (var service in ccService) {
      if (tempService?.[service]?.serviceCenterName === "") {
        tempService[service]["name_error"] = "Please Enter Service Center Name";
        isErrorOccured = true;
      }
      if (tempService?.[service]?.licenceNumber === "0") {
        tempService[service]["licenseError"] =
          "Please Enter number of licences";
        isErrorOccured = true;
      }
      if (tempService?.[service]?.licenceNumber === "") {
        tempService[service]["licenseError"] =
          "Please Enter number of licences";
        isErrorOccured = true;
      }
    }

    if (isErrorOccured) {
      setSelectedApps(tempvalue);
      setCcService(tempService);
    } else {
      const body: any = {
        id: rowData.id,
        name: name,
        address1: address1,
        address2: address2,
        phone: phone,
        state: state,
        contact1: contact1,
        contact2: contact2,
        pincode: pincode,
        emailId: email,
        country: country.name,
        country_code: country.code,
        country_dial_code: country.dial_code,
        level: level.label,
        programType: programType.label,
        region: region.label,
        contact_person_name: contactPersonName,
        city: city,
      };
      const applications: any = [];
      for (var key in selectdApp) {
        const applicationObj: any = {
          applicationId: selectdApp[key].applicationId,
          license: selectdApp[key].license,
          // startDate: selectdApp[key].startDate.toISOString().split("T")[0],
          // expireDate: selectdApp[key].endDate.toISOString().split("T")[0],
          startDate: moment(selectdApp[key]?.startDate).format("YYYY-MM-DD HH:mm:ss.SSSSSSS"),
          expireDate: moment(selectdApp[key]?.endDate).format("YYYY-MM-DD HH:mm:ss.SSSSSSS"),
          subscription_type: selectdApp[key].subscription_type.label,
          code: key,
          isDelete: selectdApp[key].isDelete,
          // id: 25, Need to add for update
        };

        if (selectdApp[key].id) {
          applicationObj["id"] = selectdApp[key].id;
        }

        applications.push(applicationObj);
      }

      body["userApplications"] = applications;
      const changes = await compareObjects(initialData, body);
      const corCData: any = [];
      for (var ser in ccService) {
        const serCObj = {
          applicationId: selectdApp["COR-C"]?.applicationId,
          name: ccService[ser].serviceCenterName,
          license: ccService[ser].licenceNumber,
          isDelete: ccService[ser].isDelete,
          isActive: ccService[ser].isActive,
          id: ccService[ser].id,
        };
        corCData.push(serCObj);
      }

      body["corCServices"] = corCData;
      dispatch(updateOrgnization(body));
      //comment above line and uncomment below to start saving the log and also updates the org data
      // dispatch(createOrgnizationLog(changes, body));
    }
  };

  const deActivateCCService = (service: any) => {
    const ccNew = ccService.map((ser: any) =>
      ser.id === service.id ? { ...ser, isActive: false } : ser
    );
    setCcService(ccNew);
  };

  const activateCCService = (service: any) => {
    const ccNew = ccService.map((ser: any) =>
      ser.id === service.id ? { ...ser, isActive: true } : ser
    );
    setCcService(ccNew);
  };

  const MyLoader = () => (
    <ContentLoader viewBox="0 0 380 26" className="rounded-lg">
      <rect x="0" y="0" rx="0" ry="0" width="100%" height="60" />
    </ContentLoader>
  );

  const onDeleteApp = () => {
    const application = { ...selectdApp };
    if (selectedOrgApp.id) {
      application[selectedOrgApp.code]["isDelete"] = true;
    } else {
      delete application[selectedOrgApp.code];
    }
    setSelectedApps(application);
    setWarningDialog(false);
  };

  const onUndo = (obj: any) => {
    const application = { ...selectdApp };
    application[obj.code]["isDelete"] = false;
    setSelectedApps(application);
    setWarningDialog(false);
  };

  useEffect(() => {
    const appObj: any = {};
    for (let i = 0; i < rowData?.license?.length; i++) {
      appObj[rowData.license[i].application.applicationCode] = {
        startDate: new Date(rowData.license[i].startDate),
        endDate: new Date(rowData.license[i].expireDate),
        license: rowData.license[i].license,
        applicationId: rowData.license[i].application.id,
        id: rowData.license[i].id,
        isDelete: false,
        applicationName: rowData.license[i].application.applicationName,
        code: rowData.license[i].application.applicationCode,
        subscription_type: { label: "Yearly", value: "Yearly" },
      };

      // const transformedCcServices =
      //   rowData.license[i].application.applicationCode === "COR-C"
      //     ? rowData.license[i].ccServices.map((service: any) => ({
      //         id: service.id,
      //         serviceCenterName: service.name,
      //         licenceNumber: service.license,
      //         isActive: service.isActive,
      //         isDelete: false,
      //       }))
      //     : [];
      if (rowData.license[i].application.applicationCode === "COR-C") {
        const transformedCcServices = rowData.license[i].ccServices.map((service: any) => ({
          id: service.id,
          serviceCenterName: service.name,
          licenceNumber: service.license,
          isActive: service.isActive,
          isDelete: false,
        }))
        setCcService(transformedCcServices);
      }
    }
    setSelectedApps(appObj);

    // const handleBeforeUnload = (event: any) => {
    //   event.preventDefault();
    //   // Custom logic to handle the refresh
    //   // Display a confirmation message or perform necessary actions
    // };

    // window.addEventListener("beforeunload", handleBeforeUnload);
    // return () => {
    //   window.removeEventListener("beforeunload", handleBeforeUnload);
    // };
  }, [rowData]);

  useEffect(() => {
    if (org.updateOrgnization) {
      navigate(-1);
      dispatch(resetUpdateOrg());
    }
  }, [org.updateOrgnization]);

  const deleteCCService = (service: any) => {
    const ccNew = ccService.filter((ser: any) => ser.ccId !== service.ccId);
    setCcService(ccNew);
  };

  return (
    <>
      <>
        <Container className="h-full bg-white p-4 min-w-[80vw] font-Popins overflow-auto">
          <div className="h-full res_height ">
            <div className="flex flex-row justify-center  bg-[#264390] max-w-[280px]  mx-auto text-white font-medium p-2 min-h-fit max-h-full rounded-sm ">
              <BuildingOfficeIcon className="w-8 h-8 mr-2 " />
              <span className="text-2xl font-medium ">Edit Organization</span>
            </div>
            <div className="Card my-3 rounded-md shadow-sm bg-[#ececec]">
              <div className="p-4 xl:h-[57vh] 2xl:h-[65vh] 1xl:h-[58vh] lg:h-[55vh] md:h-[55vh] overflow-auto">
                <span className="font-semibold text-xl">
                  Enter Organization Details
                </span>
                <div className="grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 px-1 py-3 gap-y-1 gap-x-5">
                  <TextInput
                    label={"Name"}
                    placeholder={"Name"}
                    required={true}
                    value={name}
                    error={nameError}
                    onChange={(e: any) => {
                      setName(e.target.value);
                      setNameError("");
                    }}
                  />
                  <TextInput
                    label={"Contact Person Name"}
                    placeholder={"Contact Person Name"}
                    required={true}
                    value={contactPersonName}
                    error={contactPersonNameError}
                    onChange={(e: any) => {
                      setContactPersonName(e.target.value);
                      setContactPersonNameError("");
                    }}
                  />
                  <TextInput
                    label={"Contact Person Email"}
                    placeholder={"Contact Person Email"}
                    required={true}
                    error={emailError}
                    value={email}
                    onChange={(e: any) => {
                      setEmail(e.target.value);
                      setEmailError("");
                    }}
                  />
                  <TextInput
                    label={"Address 1"}
                    placeholder={"Address 1"}
                    required={true}
                    error={address1Error}
                    value={address1}
                    onChange={(e: any) => {
                      setAddress1(e.target.value);
                      setAddress1Error("");
                    }}
                  />
                  <TextInput
                    label={"Address 2"}
                    placeholder={"Address 2"}
                    value={address2}
                    onChange={(e: any) => setAddress2(e.target.value)}
                  />
                  <TextInput
                    label={"City"}
                    placeholder={"City"}
                    required={true}
                    value={city}
                    error={cityError}
                    onChange={(e: any) => {
                      setCity(e.target.value);
                      setCityError("");
                    }}
                  />
                  <TextInput
                    label={"State"}
                    placeholder={"State*"}
                    value={state}
                    error={stateError}
                    required={true}
                    onChange={(e: any) => {
                      setState(e.target.value);
                      setStateError("");
                    }}
                  />
                  <HSSelect
                    label={"Select Country"}
                    required={true}
                    placeholder="Select Country*"
                    options={countryList.data}
                    getOptionLabel={(option: any) => option.name}
                    getOptionValue={(option: any) => option.code}
                    value={country}
                    onChange={(obj: any) => {
                      setCountry(obj);
                      setCountryError("");
                      // onChangeUserDetails(obj, "organization");
                      // setOrgDetails(obj);
                    }}
                    error={countryError}
                  />
                  <TextInput
                    label={"Pincode"}
                    placeholder={"Pincode"}
                    required={true}
                    value={pincode}
                    error={pincodeError}
                    onChange={(e: any) => {
                      const re = /^[0-9\b]+$/;
                      if (
                        (re.test(e.target.value) &&
                          e.target.value.length < 7) ||
                        !e.target.value
                      ) {
                        setPincode(e.target.value);
                        setPincodeError("");
                      }
                    }}
                  />
                  <TextInput
                    label={"Phone"}
                    placeholder={"Phone"}
                    required={true}
                    value={phone}
                    error={phoneError}
                    onChange={(e: any) => {
                      const re = /^[0-9\b]+$/;
                      if (
                        (re.test(e.target.value) &&
                          e.target.value.length < 11) ||
                        !e.target.value
                      ) {
                        setPhone(e.target.value);
                        setPhoneError("");
                      }
                    }}
                  />
                  <TextInput
                    label={"Contact 1"}
                    required={true}
                    placeholder={"Contact 1"}
                    value={contact1}
                    onChange={(e: any) => setContact1(e.target.value)}
                  />
                  <TextInput
                    label={"Contact 2"}
                    placeholder={"Contact 2"}
                    value={contact2}
                    required={true}
                    onChange={(e: any) => setContact2(e.target.value)}
                  />
                  <HSSelect
                    label={"Select Region"}
                    placeholder="Select Region*"
                    options={ORGNIZATION_REGION}
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => option.value}
                    value={region}
                    required={true}
                    onChange={(obj: any) => {
                      setRegion(obj);
                      setRegionError("");
                      // onChangeUserDetails(obj, "organization");
                      // setOrgDetails(obj);
                    }}
                    error={regionError}
                  />

                  <HSSelect
                    label={"Select Level"}
                    required={true}
                    placeholder="Select Level*"
                    options={ORGNIZATION_LEVEl}
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => option.value}
                    value={level}
                    // value={ORGNIZATION_LEVEl.filter(
                    //   (ele: any) =>
                    //     ele.value.toLowerCase() === level.toLowerCase()
                    // )}
                    onChange={(obj: any) => {
                      // setLevel(obj?.value);
                      setLevel(obj);
                      // setLevelError("");
                    }}
                    error={levelError}
                  />
                  <HSSelect
                    label={"Select Program type"}
                    required={true}
                    placeholder="Select Program type*"
                    options={ORGNIZATION_PROGRAM_TYPE}
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => option.value}
                    value={programType}
                    onChange={(obj: any) => {
                      // setProgramType(obj.label);
                      setProgramType(obj);
                      setProgramTypeError("");
                      // onChangeUserDetails(obj, "organization");
                      // setOrgDetails(obj);
                    }}
                    error={programTypeError}
                  />
                </div>

                <div className="grid grid-cols-3 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 px-1 py-3 gap-4">
                  <div />
                  <div />
                  {!application.appLoading ? (
                    <>
                      {application.appList?.length < 8 && (
                        <Menu>
                          <MenuHandler>
                            <Button
                              className="  flex flex-row items-center  bg-[#264390]  p-2 h-[46px]"
                              size="sm"
                              // disabled={!isValidUserDetails()}
                              // // color="blue"
                              onClick={() => addApplication()}
                            >
                              <ChevronDownIcon className="w-7 h-7 mr-2 " />
                              <span className="text-[15px] font-normal">
                                {" "}
                                Add Application Access{" "}
                              </span>
                            </Button>
                          </MenuHandler>

                          <MenuList
                            className={` p-0 w-[24%] relative  bg-[#264390] text-white boredr border-white rounded-none -mt-2 `}
                          >
                            {application.appList.map(
                              (menu: any, index: number) => (
                                <MenuItem
                                  key={index}
                                  className={`border-b border-white rounded-none 
                      ${
                        isSelected(menu.applicationCode)
                          ? "bg-gray-200 text-gray-600 rounded-none  "
                          : ""
                      }`}
                                  onClick={() => onSelectOption(menu)}
                                >
                                  {menu.applicationName}
                                </MenuItem>
                              )
                            )}
                          </MenuList>
                        </Menu>
                      )}
                    </>
                  ) : (
                    <MyLoader />
                  )}
                </div>

                {Object.keys(selectdApp).map((key: any, index: number) => {
                  let isError = false;
                  let isCompleted = false;
                  const obj = selectdApp[key];

                  if (
                    obj.licenseError ||
                    obj.startDateError ||
                    obj.endDateError ||
                    obj.subscription_type_error
                  ) {
                    isError = true;
                  } else {
                    if (
                      obj.license &&
                      obj.startDate &&
                      obj.endDate &&
                      obj.subscription_type
                    ) {
                      isCompleted = true;
                    }
                  }

                  return (
                    <Accordion
                      open={openExisting === index}
                      className={`mb-2 rounded-lg border border-blue-gray-100 text-center shadow-xl ${
                        obj.isDelete ? "opacity-10" : ""
                      }`}
                    >
                      <AccordionHeader
                        onClick={() => toggleHeader(index, true)}
                        className={`bg-[#264390] text-white ${
                          openExisting == 0
                            ? "rounded-lg rounded-b-none"
                            : "rounded-lg "
                        } h-[40px]  flex justify-between`}
                      >
                        <div className=" w-full flex flex-row justify-between items-center">
                          <div className="flex flex-row items-center">
                            <p className="mt-3 px-4">{obj.applicationName}</p>
                            {isError && (
                              <ExclamationCircleIcon
                                className="w-8 h-8"
                                color={"#f2382e"}
                              />
                            )}
                            {isCompleted && (
                              <CheckBadgeIcon
                                className="w-8 h-8"
                                color={"#07e015"}
                              />
                            )}
                          </div>
                          <div className=" flex flex-row">
                            <div className="flex text-white">
                              {obj.isDelete ? (
                                <ArrowUturnDownIcon
                                  className="w-8 h-8 bg-[#264390] p-1"
                                  onClick={(event) => {
                                    onUndo(obj);
                                    event.stopPropagation();
                                  }}
                                />
                              ) : (
                                <TrashIcon
                                  className="w-8 h-8 bg-[#264390] p-1"
                                  onClick={(event) => {
                                    setWarningDialog(true);
                                    setSelectedOrgApp(obj);
                                    event.stopPropagation();
                                  }}
                                />
                              )}
                            </div>
                            {/* <ChevronDownIcon className="w-8 h-8 ml-2 items-end" /> */}
                          </div>
                        </div>
                      </AccordionHeader>
                      <AccordionBody className="pt-0 text-base font-normal bg-white">
                        <div className="flex flex-row w-full items-center">
                          <Image
                            className="px-3 py-2"
                            src={
                              menuList.filter(
                                (ele: any) => ele.applicationCode === obj.code
                              )[0]?.logo
                            }
                            width={180}
                            height={72}
                            alt="COR Advantage"
                          />

                          <div className="grid grid-cols-4 lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-4 px-5 py-3 w-full ">
                            <TextInput
                              placeholder="Licence"
                              required={true}
                              crossOrigin={undefined}
                              value={obj.license}
                              type="number"
                              label="Licence"
                              disabled={
                                obj.isDelete ||
                                (obj.code === "COR-C" && ccService?.length > 0)
                              }
                              onChange={(e: any) => {
                                onValunChange(
                                  obj.code,
                                  "license",
                                  e.target.value
                                );
                              }}
                            />
                            <div className="flex flex-col">
                              <div className="w-full flex ">
                                <div className="text-red-600">*</div>
                                Start Date
                              </div>

                              <DatePicker
                                className="h-[44px] border-2 border-[#264390] rounded-md px-2 w-full"
                                selected={
                                  obj.startDate
                                    ? new Date(obj.startDate)
                                    : undefined
                                }
                                disabled={obj.isDelete}
                                onChange={(date: any) => {
                                  onValunChange(obj.code, "startDate", date);
                                }}
                                placeholderText="MM/DD/YYYY"
                                // showIcon={true}
                                // icon={
                                //   <CalendarMonthOutlinedIcon className="w-16 h-16 mt-[2px] items-end ml-[90%]" />
                                // }
                                title={"Start Date"}
                                required={true}
                              />
                            </div>

                            <div className="flex flex-col">
                              <div className="w-full flex ">
                                <div className="text-red-600">*</div>
                                Subscription Type
                              </div>
                              <HSSelect
                                placeholder="Subscription Type*"
                                options={SUBSCRIPTION_TYPE}
                                getOptionLabel={(option: any) => option.label}
                                getOptionValue={(option: any) => option.value}
                                // value={selectdApp[obj.code].subscription_type}
                                value={SUBSCRIPTION_TYPE}
                                disabled={obj.isDelete}
                                onChange={(obj: any) => {
                                  // setRegion(obj);
                                  // setRegionError("");
                                  // onChangeUserDetails(obj, "organization");
                                  // setOrgDetails(obj);
                                }}
                                // error={
                                //   selectdApp[obj.code].subscription_type_error
                                // }
                              />
                            </div>

                            <div className="w-[100%] flex flex-col">
                              <div className="w-full flex">
                                <div className="text-red-600">*</div>
                                Expire Date
                              </div>
                              <DatePicker
                                className="h-[44px] border-2 border-[#264390] rounded-md px-2 w-full"
                                selected={
                                  obj.endDate
                                    ? new Date(obj.endDate)
                                    : undefined
                                }
                                minDate={obj.startDate}
                                disabled={obj.isDelete || !obj.startDate}
                                onChange={(date: any) => {
                                  onValunChange(obj.code, "endDate", date);
                                }}
                                placeholderText="MM/DD/YYYY"
                                // disabled={!selectdApp[obj.code].startDate}
                                // minDate={selectdApp[obj.code].startDate}
                                // showIcon={true}
                                // icon={
                                //   <CalendarMonthOutlinedIcon className="w-16 h-16 mt-[2px] items-end ml-[90%]" />
                                // }
                                required={true}
                              />
                            </div>
                          </div>
                        </div>
                        {obj.code === "COR-C" && (
                          <div className="px-4">
                            <div className="">
                              <Button
                                className="flex flex-row items-center justify-center bg-[#264390] hover:bg-gray-500 py-3 px-5 2xl:px-4 2xl:py-3 rounded-md"
                                onClick={() => addCCService()}
                              >
                                <span className="text-[15px] 2xl:text-[18px] font-semibold hover:text-[#264390]">
                                  + Add Service Center (Level 2)
                                </span>
                              </Button>
                            </div>

                            {ccService?.map((service, index) => (
                              <div
                                key={index}
                                className={`border border-[#264390] bg-[#ececec] mt-3 grid grid-cols-3 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-3 items-center `}
                              >
                                <div
                                  className={`${
                                    !service.isActive ? "opacity-40" : ""
                                  } ml-4 mr-4`}
                                >
                                  <TextInput
                                    label={"Service Center Name"}
                                    placeholder={"Service Center Name"}
                                    required={true}
                                    value={service.serviceCenterName}
                                    error={service.name_error}
                                    disabled={!service.isActive}
                                    onChange={(e: any) => {
                                      service.serviceCenterName =
                                        e.target.value;
                                      service.name_error = undefined;
                                      setCcService([...ccService]);
                                    }}
                                  />
                                </div>
                                <div
                                  className={`${
                                    !service.isActive ? "opacity-40" : ""
                                  } ml-4 mr-4`}
                                >
                                  <TextInput
                                    placeholder="Number of Licences"
                                    required={true}
                                    crossOrigin={undefined}
                                    type="number"
                                    label="Licence"
                                    disabled={!service.isActive}
                                    error={service.licenseError}
                                    value={service.licenceNumber}
                                    // error={nameError}

                                    onChange={(e: any) => {
                                      service.licenceNumber = e.target.value;
                                      service.licenseError = undefined;
                                      setCcService([...ccService]);
                                    }}
                                  />
                                </div>
                                {/* </div> */}
                                {!service.id && (
                                  <Button
                                    className="flex flex-row items-center justify-center m-4 bg-[#264390] hover:bg-gray-500 py-3 px-5 2xl:px-4 2xl:py-3 rounded-md !capitalize "
                                    onClick={() => {
                                      deleteCCService(service);
                                    }}
                                  >
                                    <span className="text-[15px] 2xl:text-[18px] font-semibold hover:text-[#264390]">
                                      {" "}
                                      Delete
                                    </span>
                                  </Button>
                                )}

                                {service.id && service.isActive && (
                                  <Button
                                    className="flex flex-row items-center justify-center m-4 bg-[#264390] hover:bg-gray-500 py-3 px-5 2xl:px-4 2xl:py-3 rounded-md !capitalize "
                                    onClick={() => deActivateCCService(service)}
                                  >
                                    <span className="text-[15px] 2xl:text-[18px] font-semibold hover:text-[#264390]">
                                      {" "}
                                      Deactivate
                                    </span>
                                  </Button>
                                )}
                                {service.id && !service.isActive && (
                                  <Button
                                    className="flex flex-row items-center justify-center m-4 bg-[#264390] hover:bg-gray-500 py-3 px-5 2xl:px-4 2xl:py-3 rounded-md !capitalize "
                                    onClick={() => {
                                      activateCCService(service);
                                    }}
                                  >
                                    <span className="text-[15px] 2xl:text-[18px] font-semibold hover:text-[#264390]">
                                      {" "}
                                      Activate
                                    </span>
                                  </Button>
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                      </AccordionBody>
                    </Accordion>
                  );
                })}
              </div>
              <div
                className={`flex justify-end px-5 border-t-2 border-gray-400 py-3 h-[60px]`}
              >
                <Button
                  className="flex flex-row items-center justify-center bg-[#264390] hover:bg-gray-500 py-3 px-3 2xl:px-4 2xl:py-3 rounded-md"
                  onClick={() => onSubmit()}
                >
                  <span className="text-[15px] 2xl:text-[18px] font-semibold hover:text-[#264390]">
                    {" "}
                    Submit
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </>
      {selectedOrgApp && warningDialog && (
        <ConfirmationDialog
          open={warningDialog}
          message={
            selectedOrgApp.id
              ? "Are you sure? you want to delete this orgnization, It may affect in application level, it will remove all access for this orgnization, All User from this orgnization wont be able to access this application"
              : "Are you sure? You want to delete this application."
          }
          onConfirm={() => onDeleteApp()}
          onCancel={() => setWarningDialog(false)}
        />
      )}

      <HSDialog
        header={"Error"}
        message="Please select at least one application"
        handleOpen={(message: any) => showAlertDialog(message)}
        open={alertDialog}
        size={"xs"}
      />
    </>
  );
};
