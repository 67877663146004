import {
  ArrowRightOnRectangleIcon,
  Bars3Icon,
  HandThumbUpIcon,
  InformationCircleIcon,
  UserCircleIcon,
  KeyIcon,
} from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { TOGGLE_SIDE_MENU } from "../redux/actionConstants";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { RootState } from "../redux/store";
import { Router, useNavigate, useRoutes } from "react-router-dom";
interface PropsType {
  className?: string | undefined;
}

function Topbar({ className }: PropsType) {
  const [open, setOpen] = useState("");
  const state = useSelector((state: any) => state.app);
  const userDetails = useSelector((state: RootState) => state.user.userDetails);
  const dispatch = useDispatch();
  const onToggle = () => {
    dispatch({ type: TOGGLE_SIDE_MENU, payload: !state?.isHidden });
  };
  const navigate = useNavigate();

  const { instance, inProgress } = useMsal();
  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  const handleResetPassword = () => {
    const authority = process.env.REACT_APP_AZURE_RESET_PASSWORD_AUTHORITY;
    instance.loginRedirect({
      authority: authority,
      scopes: ["offline_access"],
    });
  };

  const handleLogoutRedirect = () => {
    instance.logoutRedirect();
  };
  const changePassword = () => {
    // console.log("hey");
    // window.location.href = `https://hsplatformtest.b2clogin.com/hsplatformtest.onmicrosoft.com/oauth2/v2.0/authorize?
    // p=B2C_1A_CUSTOMCHANGEPASSWORD_RP
    // &client_id=<b2ef2fdd-18b1-469f-8a6a-a3438551fe47>
    // &nonce=defaultNonce
    // &redirect_uri=<https://hsplatformtest.azurewebsites.net/auth>
    // &scope=openid
    // &response_type=id_token
    // &prompt=login`;
    const authority = process.env.REACT_APP_AZURE_RESET_PASSWORD_AUTHORITY;
    instance.loginRedirect({
      authority: authority,
      scopes: ["offline_access"],
    });
  };

  return (
    <header
      className={`font-poppins h-full flex items-center  justify-between  xl:mx-auto max-w-full flex-wrap w-full`}
    >
      <span className="flex self-center text-xl  whitespace-nowrap font-popins text-white ml-2 capitalize">
        <Bars3Icon
          className="h-8 w-8 mr-1 hover:cursor-pointer"
          onClick={onToggle}
        />
        {userDetails && (
          <>
            <p className="font-normal mt-1">
              Welcome : {"  "}
              <span className="font-semibold">
                {userDetails.firstName + " " + userDetails.lastName}{" "}
              </span>
            </p>
          </>
        )}
      </span>

      <nav>
        <AuthenticatedTemplate>
          <ul className="text-base flex flex-row text-gray-600 md:flex lg:justify-between space-x-3 mt-1 top-bar ">
            <li className="bg-[#99adeb] ">
              <a
                className="lg:px-3  text-white flex px-2 py-1 text-xs font-medium onboard-program-navigation cursor-pointer"
                href="https://highscope.org/technical-support/"
                rel="noreferrer"
                target="_blank"
              >
                <InformationCircleIcon className="h-4 w-4 mr-1" /> Help{" "}
              </a>
            </li>
            <li
              className="bg-[#99adeb] "
              onClick={() => navigate("userProfile")}
            >
              {/* <p className="lg:px-3  text-white flex px-2 py-1 text-xs font-medium onboard-program-navigation cursor-pointer">
                <UserCircleIcon className="h-4 w-4 mr-1" /> User Profile{" "}
              </p> */}
            </li>

            <li className="bg-[#99adeb] " onClick={() => changePassword()}>
              <p className="lg:px-3  text-white flex px-2 py-1 text-xs font-medium onboard-program-navigation cursor-pointer">
                <KeyIcon className="h-4 w-4 mr-1" /> Change Password{" "}
              </p>
            </li>
            <li className="bg-[#99adeb]" onClick={() => handleLogoutRedirect()}>
              <p className="lg:px-3  flex text-white px-2 py-1 text-xs font-medium onboard-program-navigation cursor-pointer">
                <ArrowRightOnRectangleIcon className="h-4 w-4 mr-1" /> Logout
              </p>
            </li>
          </ul>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <ul className="text-base flex flex-row text-gray-600 md:flex lg:justify-between space-x-3 mt-1 top-bar ">
            <li className="bg-[#99adeb] " onClick={() => handleLoginRedirect()}>
              <p className="lg:px-3  text-white flex px-2 py-1 text-xs font-medium onboard-program-navigation cursor-pointer">
                <UserCircleIcon className="h-4 w-4 mr-1" /> Login{" "}
              </p>
            </li>

            <li className="bg-[#99adeb] " onClick={() => handleResetPassword()}>
              <p className="lg:px-3  text-white flex px-2 py-1 text-xs font-medium onboard-program-navigation cursor-pointer">
                <UserCircleIcon className="h-4 w-4 mr-1" /> Reset/Forgot
                Password{" "}
              </p>
            </li>
          </ul>
        </UnauthenticatedTemplate>
      </nav>
    </header>
  );
}

export default Topbar;
