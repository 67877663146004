import {
  CameraIcon,
  PencilSquareIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { PlayCircle } from "@mui/icons-material";
import React, { ReducerAction, useEffect, useRef, useState } from "react";
import { Container, Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { Link, useNavigate } from "react-router-dom";
import { Avatar } from "@material-tailwind/react";
import { useAppDispatch } from "../hooks/app";
import { getUserDetails, uploadProfilePicture } from "../redux/actions/user";
import { getAllApplications } from "../redux/actions/application";
import { GET_USER_DETAILS_SUCCESS } from "../redux/actionConstants";
import { useMsal } from "@azure/msal-react";
import { requestCompleted, requestStarted } from "../redux/actions/app";

export default function UserProfile() {
  const userDetails = useSelector((state: RootState) => state.user.userDetails);
  const { instance } = useMsal();
  const activeAccount: any = instance.getActiveAccount();

  useEffect(() => {
    dispatch(getUserDetails(activeAccount.idTokenClaims.oid));
  }, []);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [file, setFile] = useState<any>();

  const applicationList = useSelector(
    (state: RootState) => state.application.appList
  );

  useEffect(() => {
    dispatch(getAllApplications());
  }, []);

  const ref: any = useRef();
  const   handleClick = (e: any) => {
    ref.current.click();
  };
  const handleFileChange = async (e: any) => {
    if (e.target.files) {
      var file = e.target.files[0];
      previewFile(file);
      dispatch(uploadProfilePicture(userDetails.id, file));
    }
  };
  function previewFile(file: any) {
    var reader = new FileReader();
    reader.onloadend = function () {
      setFile(reader.result);
    };
    reader.readAsDataURL(file);
  }

  useEffect(() => {
    setFile(userDetails?.profileUrl);
  }, [userDetails?.profileUrl]);

  const onClickAction = () => {
    if (
      userDetails.role == "HighscopeAdmin" ||
      userDetails.userDetails.every(
        (userDetail: any) => userDetail.application_role.role_id === 100005
      )
    ) {
      navigate(`/editHSAdmin/${userDetails.id}/admin`);
    } else {
      navigate(`/editUser/${userDetails?.id}/admin`);
    }
  };

  return (
    <>
      <Container className="h-full bg-white py-4 min-w-[82vw] font-['poppins'] overflow-auto ">
        {userDetails ? (
          <div className="h-full res_height  ">
            <div className="flex flex-row justify-center  bg-[#264390] max-w-[300px]  mx-auto text-white font-medium p-2 min-h-fit max-h-full rounded-sm ">
              <UserCircleIcon className="w-8 h-8 mr-2 " />
              <span className="text-2xl font-medium ">User Profile</span>
            </div>

            <div>
              <div
                className="mt-2 relative file_shrink_main bg-gray-100 overflow-hidden border border-gray-500 rounded-full
                             w-[150px]  h-[70%] m-auto"
              >
                <div className="file_open  w-full h-full bg-black absolute z-10 text-center cursor-pointer ">
                  <div onClick={handleClick}>
                    <label htmlFor="file-input" className="cursor-pointer">
                      <CameraIcon className="text-white w-7 mx-auto mt-3" />
                    </label>
                  </div>
                  <input
                    ref={ref}
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={handleFileChange}
                  />
                </div>
                <Avatar
                  className=" rounded-full  "
                  src={
                    file ||
                    "https://www.postendekker.nl/wp-content/uploads/2021/10/dummy-profile.jpg"
                  }
                  alt="Modern building architecture"
                  width={120}
                  height={120}
                  style={{ width: "150px", height: "150px" }}
                />
              </div>
            </div>
            <div className="mb-5">
              <div className="card mt-2  w-[65%] m-auto shadow-lg">
                <div>
                  <p className="bg-gray-300 p-2 text-xl font-semibold flex justify-between text-[#264390]">
                    Account Information{" "}
                    <PencilSquareIcon
                      className="w-7 h-7"
                      // onClick={() =>
                      //   navigate(`/editUser/${userDetails?.id}/admin`)
                      // }
                      onClick={onClickAction}
                    />
                  </p>
                  <div className="px-4 grid grid-cols-2  md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-x-10 pt-2">
                    <span className="flex text-lg font-semibold">
                      Name:{" "}
                      <p className="ml-2 font-normal">
                        {userDetails.firstName} {userDetails.middleName}{" "}
                        {userDetails.lastName}
                      </p>{" "}
                    </span>
                    <span className="flex text-lg font-semibold">
                      Username:{" "}
                      <p className="ml-2 font-normal">{userDetails.username}</p>{" "}
                    </span>
                    <span className="flex text-lg font-semibold">
                      Email:{" "}
                      <p className="ml-2 font-normal">{userDetails.email}</p>{" "}
                    </span>
                    <span className="flex text-lg font-semibold">
                      Phone:{" "}
                      <p className="ml-2 font-normal">{userDetails.phone}</p>{" "}
                    </span>
                  </div>

                  {userDetails?.orgnization && (
                    <>
                      <p className="bg-gray-300 p-2 text-xl font-semibold mt-3 text-[#264390]">
                        Organization
                      </p>
                      <div className="px-4 grid grid-cols-2">
                        <span className="flex text-lg font-semibold">
                          Organization Name:
                          <p className="ml-2 font-normal">
                            {userDetails?.orgnization?.name}
                          </p>{" "}
                        </span>
                      </div>
                    </>
                  )}

                  <div>
                    {userDetails.role == "HighscopeAdmin" ? (
                      <div>
                        <p className="bg-gray-300 p-2 text-xl font-semibold mt-3 text-[#264390]">
                          Role
                        </p>
                        <div className="px-4 grid grid-cols-2 ">
                          <span className="flex text-lg font-semibold">
                            Application Role:
                            <p className="ml-2 font-normal">
                              Highscope Admin
                            </p>{" "}
                          </span>
                        </div>
                      </div>
                    ) : (
                      " "
                    )}
                  </div>

                  {userDetails.role !== "HighscopeAdmin" ? (
                    <div>
                      <p
                        className="bg-gray-300 p-2 text-xl font-semibold mt-3 
                                text-[#264390]"
                      >
                        Applications & Role
                      </p>
                      <div className="pb-4 px-4 grid grid-cols-2  md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-x-10">
                        <div>
                          <span className="flex text-lg font-semibold">
                            Application Name{" "}
                          </span>
                          {userDetails?.userDetails?.map(
                            (data: any, index: any) => (
                              <div className="pt-1">
                                <span className="flex text-lg ">
                                  {data.application.applicationName} -{" "}
                                </span>
                              </div>
                            )
                          )}

                          {/* {userDetails.role == "HighscopeAdmin" ? (
                          <div className="">
                            {applicationList.map((data: any, index: any) => (
                              <div className="flex pt-1">
                                <span className=" text-lg">
                                  {data.applicationName} -{" "}
                                </span>
                              </div>
                            ))}
                          </div>
                        ) : (
                          ""
                        )} */}
                        </div>
                        <div>
                          <span className="flex text-lg font-semibold">
                            Application Role
                          </span>
                          {userDetails?.userDetails?.map(
                            (data: any, index: any) => (
                              <div className="pt-1">
                                <span className="flex text-lg ">
                                  {data.mainRole}
                                </span>
                              </div>
                            )
                          )}
                          {/* {userDetails.role == "HighscopeAdmin" ? (
                          <div>
                            {Array.from(
                              { length: applicationList.length },
                              (index: any) => (
                                <div key={index} className="pt-1">
                                  <span className="flex text-lg">
                                    {userDetails.role}
                                  </span>
                                </div>
                              )
                            )}
                          </div>
                        ) : (
                          ""
                        )} */}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="h-[10px]" />
          </div>
        ) : (
          <></>
        )}
      </Container>
    </>
  );
}
